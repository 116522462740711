export default {
  data () {
    return {
      notificationPermission: false,
      notificationInWindow: false,
      interval: null
    }
  },
  mounted () {
    console.debug('[notification.js][mounted()]')

    this.notificationInWindow = 'Notification' in window

    if (this.notificationInWindow) {
      this.notificationPermission = Notification.permission

      this.interval = setInterval(() => {
        this.notificationPermission = Notification.permission
      }, 3000)
    }
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  methods: {
    subscribeToNotifications () {
      console.debug('[notification.js][subscribeToNotifications()]')

      Notification.requestPermission((result) => {
        this.notificationPermission = result
        if (result !== 'granted') {
          console.debug('No notification permission granted because ', result)
        } else {
          console.debug('Notification permission granted')
          this.configurePushSub()
        }
      })
    },
    showDisabledModal () {
      console.debug('[notification.js][showDisabledModal()]')

      this.$nuxt.$emit('show-notification-off-box')
    },
    deleteCurrentPushSub () {
      if (!this.notificationInWindow ) {
        return
      }
      console.debug('[notification.js][deleteCurrentPushSub()]')

      return navigator.serviceWorker.ready
        .then(function (swreg) {
          return swreg.pushManager.getSubscription()
        })
        .then((sub) => {
          if (sub != null) {
            return this.$axios.delete('/api/push/', { data: { endpoint: sub.endpoint } })
          }
          return sub
        })
    },
    configurePushSub () {
      if ('granted' !== this.notificationPermission || !('serviceWorker' in navigator)) {
        console.debug('[notification.js][configurePushSub()] not subscribing')
        console.debug('[notification.js][configurePushSub()] \'granted\' !== this.notificationPermission', 'granted' !== this.notificationPermission)
        console.debug('[notification.js][configurePushSub()] this.notificationPermission', this.notificationPermission)
        console.debug('[notification.js][configurePushSub()]  !(\'serviceWorker\' in navigator)', !('serviceWorker' in navigator))

        return
      }
      console.debug('[notification.js][configurePushSub()]')

      let registration

      navigator.serviceWorker.ready
        .then(function (swRegistration) {
          console.debug('[notification.js][configurePushSub()] swRegistration', swRegistration)
          registration = swRegistration
          return swRegistration.pushManager.getSubscription()
        })
        .then((pushSubscription) => {
          if (pushSubscription === null) {
            var vapidPublicKey = process.env.VAPID_PUBLIC_KEY
            var convertedVapidPublicKey = this.urlBase64ToUint8Array(vapidPublicKey)
            return registration.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: convertedVapidPublicKey
            })
          } else {
            return pushSubscription
          }
        })
        .then((pushSubscription) => {
          console.debug('[notification.js][configurePushSub()] creating or updated pushSubscription', pushSubscription)

          return this.$axios.post('/api/push/', JSON.stringify(pushSubscription), {
            headers: {
              // Overwrite Axios's automatically set Content-Type
              'Content-Type': 'application/json'
            }
          })
        })
    },
    urlBase64ToUint8Array (base64String) {
      var padding = '='.repeat((4 - base64String.length % 4) % 4)
      var base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/')

      var rawData = window.atob(base64)
      var outputArray = new Uint8Array(rawData.length)

      for (var i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i)
      }
      return outputArray
    },
  }
}
