export const state = () => ({
  newest: {
    id: null,
    end_at: null
  },
})

export const mutations = {
  SET_NEWEST (state, newest) {
    state.newest = newest
  },
}

export const actions = {
  async fetchNewest ({ commit }) {
    try {
      let response = await this.$axios.get('/api/gpb-subscription/newest')
      commit('SET_NEWEST', response.data)
    } catch (e) {
      console.log('!!! e', e)

      this.$sentry.setContext('fetch user error', {
        'user_id': this.$auth.user.id
      })

      this.$sentry.captureException(e)
    }
  },
}

