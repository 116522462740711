export default {
  login: 'Login',
  login_do: 'Login',
  logout: 'Log out',
  or: 'or',
  terms: 'Regulations',
  cookies_policy: 'Cookies policy',
  privacy_policy: 'Privacy policy',
  email: 'E-mail',
  password: 'Password',
  success: 'Made it!',
  comeback: 'Return',
  is: 'do',
  login_to: 'login',
  something_wrong: 'Sorry, something went wrong',
  ask_for_another_email: 'Request a new email',
  send: 'Send',
  find_love_online: 'Find love online',
  fast_login: 'Quick login',
  hi: 'Hello',
  woman: 'Woman',
  man: 'Male',
  continue: 'Next',
  cancel: 'Cancel',
  save_changes: 'Save changes',
  premium_for_month: 'You are premium for the next month!',
  see_partners_suggestions: 'See partner suggestions',
  kilometer: 'km',
  from: 'from',
  reset_do: 'Reset',
  parameters: 'Parameters',
  change_do: 'Change',
  city: 'City',
  distance: 'Distance',
  save_parameters: 'Save parameters',
  propositions: 'Suggestions',
  hearts: 'Hearts',
  your_pairs: 'Your couples',
  your_profile: 'Your profile',
  check_profile: 'View profile',
  problem: 'Problem',
  are_you_sure: 'Are you sure?',
  change_order: 'Save order',
  yes_delete: 'Yes, delete',
  hide_menu: 'Hide menu',
  show_menu: 'Show menu',
  month: 'month',
  three_months: 'months',
  twelve_months: 'months',
  go_back_home: 'Back to home page',
  see_more: 'See more',
  and: 'and',
  close: 'Close',
  get_now: 'Get it now',
  buy_now: 'Buy Now',
  you_are_pair_with: 'You guys are a couple with',
  you_are_offline: 'It looks like you are offline. <a href="/">Reload page</a>',
  close_this_window: 'Close this window',
  not_now: 'Not now',
  not_found: 'Not found',
  something_went_wrong: 'Something_went_wrong :(',
  homepage: 'Homepage',
  account: {
    confirmed: 'Your account has been confirmed! You can log in! ',
    confirmed_already: 'Your account has already been confirmed',
    wrong_password: 'Wrong login or password',
    cant_login_now: 'Unable to login now',
    login: 'Login',
    no_account: 'You don\'t have an account?',
    your: 'Your account',
    password: {
      change: 'Change password',
      forgot: 'Forgot your password?',
      remind: 'Remind password',
      remind_now: 'Remind now',
      change_now: 'Change password',
      changed_successfully: 'Successful password change! You can log in ',
      check_your_email: 'Check your email',
      to_reset_password: 'to recover your password',
      current: 'Current password',
      new: 'New password',
      repeat_new: 'Repeat new password',
      change_success: 'Password changed!',
    },
    lang_choose: {
      is: 'Language selection',
    },
    notification_preferences: {
      is: 'Notification preferences',
      email_notification: 'Email notifications',
      about_hearts: 'Receive notifications about delivered hearts',
      about_pairs: 'Receive notifications about new couples',
      about_payments: 'Receive payment status notifications',
      about_messages: 'Receive notifications of new messages',
      push_status_text: 'Status of notifications on this device and this viewer',
      push_not_yet_given: 'not yet granted',
      push_given: 'granted',
      push_not_given: 'not granted'
    }
  },
  web_push: {
    on: 'Enable notifications',
    off: 'Notifications disabled',
    we_cant_turn_on: 'We cannot turn on notifications',
    manual_turn_on_help: 'To enable notifications, you must go to your browser settings and grant notification permissions for our website yourself.',
    turn_on_description: 'Enable notifications for new <br><strong>hearts</strong>, <strong>couples</strong>, <strong>messages</strong>?',
    yes_turn_on: 'Yes, enable'
  },
  chat: {
    search: 'search ...',
    no_new: 'You don\'t have any chats',
    lets_talk: 'Let\'s talk!',
    choose_person: 'Choose the person you want to chat with.',
    no_more_messages: 'No more messages',
    load_more_messages: 'Load more messages',
    send: 'Send',
    do_you_want_to_dislike: 'Do you want to dismiss the message?',
    do_you_want_to_dislike_text: 'If you reject the message, further communication with the user will not be possible',
    yes_dislike: 'Yes, decline',
    new_messages: 'new messages',
    new_message: 'new message',
    read: 'Read'
  },
  register: {
    do: 'Register',
    on_jaty: 'Registration on JaTy',
    description_text: 'A chance to find the perfect partner. <br>Fast and secure registration.',
    you_got_free_month: 'You got a <b>free</b>month from us!',
    social_login_error: 'Unable to login with Facebook. Probably your profile does not provide an email address.',
    or_register: 'or register',
    first_name: 'Name / nickname',
    accept_policy: 'I accept the <a href="/polityka-prywatnosci" target="_blank" rel="nofollow">privacy policy</a> and <a href="/regulamin" target="_blank" rel="nofollow">terms of conditions</a>. '
  },
  contact: {
    do: 'Contact',
    with_us: 'Contact with JaTy',
    with_us_do: 'Contact us.',
    topic: 'Subject',
    describe: 'Describe your problem',
    thanks: 'Thank you for submitting the form. We will reply as soon as possible!',
  },
  facebook: {
    login: 'Login with Facebook'
  },
  faq: {
    is: 'FAQ',
    how_heart_works: 'How does "heart" work?',
    how_heart_works_answer: 'The moment you and the other person send each other "hearts" you will automatically be paired and become couple. The moment you press "heart" for the first time, you will automatically show interest - which means that the person will receive a notification. ',
    make_attractive: 'How can I increase my attractiveness to JaTy?',
    make_attractive_answer_first_part: 'First of all, remember to fill in your profile with photos and all information according to the available forms. This will help you make sure that the users viewing your profile will be relevant to you. ',
    make_attractive_answer_second_part: 'Remember that your photo selection is also important. They should be of good quality, representing you and only you. Graphics, pictures, and avatars are generally not recommended. ',
    inappropriate_content: 'Someone posted inappropriate content!',
    inappropriate_content_answer_first_part: 'On pressing the icon',
    inappropriate_content_answer_second_part: 'you will be able to report the user\'s profile, and our admins will take care of the user as a priority in order to remove inappropriate content.',
    user_blocking: 'How does user blocking work?',
    user_blocking_answer: 'The user will not be able to write a message to you, he will not be able to see you on the portal, but you will also not be able to see him in the search results.',
    no_answer: 'Didn\'t find the answer?',
    no_answer_answer: 'Contact us via the application form.',
  },
  welcome: {
    incentive: 'Complete as much information as possible in your profile to help us find the best people suggestions for you',
    sex: 'Your gender',
    looking_for_women: 'Women',
    looking_for_men: 'Men',
    looking_for: 'I\'m looking for',
    your_city: 'Your city',
    your_year: 'Your year',
    preferred_partner_age: 'I am looking for age partners',
    about_title: 'Write something about yourself',
    about_text: `
                <p class="mb-6">Let other people get to know you better. Write something about yourself in a few words or sentences.</p>
                <p>You can write:</p>
                <ul class="mb-6 dotted">
                    <li>what kind of person you are</li>
                    <li>what do you do and what are your hobbies</li>
                    <li>what kind of person are you looking for and what are your intentions,</li>
                    <li>are you out of words? Insert the emoji 😉</li>
                </ul>`,
    about_me: 'About me',
    smile: 'Smile!',
    images_text: `Upload your best photos to impress your profile visitors.`,
    use_face_image: 'Select a photo that shows your face,',
    max_size: 'use photos in jpg or png format up to 8MB,',
    use_your_images: 'don\'t use someone else\'s photos,',
    use_appropriate_images: 'avoid uploading offensive, nude photos.',
    images_drop: `
                <p class="is-hidden-desktop-only is-hidden-touch">
                    Click here or drag photos from your computer.
                </p>
                <p class="is-visible-desktop is-hidden-widescreen">Click here to add photos.</p>
                <p class="m-0">
                    You can change the order of the photos.
                </p>`,
    max_images: 'Maximum number of photos is 8',
    image_required: 'Photo upload is required.',
    image_from_fb: 'We downloaded your photos from Facebook\'a',
    main_after_text: 'Main photo',
    skip_this_step: 'Skip this step',
    you_can_add_images_later: 'You can add photos later from the profile editing level',
    confirm_skipping: 'Yes, go ahead',
  },
  emoji_picker: {
    most_used: 'Most used',
    people: 'People',
    nature: 'Nature',
    objects: 'Objects',
    places: 'Places',
    symbols: 'Symbols',
  },
  help: {
    is: 'Help',
    and_contact: 'Help and contact',
    center: 'Help Center',
    faq: 'FAQ',
  },
  users: {
    currently_looking_at_distance: 'Currently looking within distance',
    from_city: 'town',
    search_parameters: 'Search parameters',
    age_you_are_looking: 'Looking for people of age',
    change_age: 'Change age',
    add_images: 'Add a photo',
    add_images_description: 'Add your photo to help others find you and unlock partner suggestions.',
    go_to_image_adding: 'Go to adding photos',
    want_to_see_more: 'Want to see more people?',
    go_change_your_preferences: 'Adjust your preferences to see more people suggestions.',
    change_preferences: 'Change parameters',
    he_sent_you_a_heart: 'He sent you a heart',
    she_sent_you_a_heart: 'She sent you a heart',
    height: 'Height',
    physique: 'Body structure',
    children: 'Children',
    eye_color: 'Eye color',
    marital_status: 'Marital status',
    work: 'Work',
    education: 'Education',
    confession: 'Confession',
    attitude_to_marriage: 'Attitude to marriage',
    report: 'Report',
    block: 'Block',
    report_user: 'Report user',
    reason: 'Reason',
    ugly_profile: 'Offensive profile - photos / description',
    report_other: 'Other',
    stealing_personal_data: 'Attempt to extort personal data',
    stealing: 'Attempted to steal / extort money',
    hate_talk: 'Propagating Hatred',
    fake: 'Spoofing (suspected fake account)',
    also_block_user: 'Block the user additionally',
    sure_to_black_and_unpair: 'Are you sure you want to block the user and break the couple?',
    sure_to_black: 'Are you sure you want to block the user?',
    block_user: 'User lock',
    yes_block: 'Yes, block',
    blocked: 'User has been blocked',
    thanks_for_report: 'Thank you for reporting',
    write_more: 'write more...',
    looking_for: 'I\'m looking for',
    looking_for_women: 'women',
    looking_for_men: 'men',
    aged: 'in&nbsp;age',
    click: 'Press',
    and_start_talking: 'and start chatting <br>chat!',
    visits: 'Visits',
    images: 'Photos',
    who_visited_you: 'Who visited you?',
    deleted: 'The selected user does not exist',
    one_time: 'time',
    times: 'times',
    no_new_visits: 'You have no new visits',
    users_not_found: 'User not found',
    pair: {
      are_you_sure_to_break: 'You want to break a couple',
      are_you_sure_to_break_confirm: 'Are you sure you want to do this?',
      yes_break: 'Yes, break it'
    },
    heart: {
      cancel_q: 'Do you want to cancel the heart?',
      cancel_confirm: 'The heart you sent earlier will be canceled.',
      yes_cancel: 'Yes, cancel the heart',
    }
  },
  current_user: {
    edit_profile: 'Edit Profile',
    edit_images: 'Edit Photos',
    basic_info: 'Basic information',
    edit_success: 'Editing profile successful!',
    more: {
      about_me: 'More about me'
    },
    preferences: {
      is: 'Preferences'
    },
    images: {
      is: 'Your photos',
      header: 'Present your <span>best</span>side!',
      from_computer: 'Click here or drag photos from computer',
      from_mobile: 'Click here to add photos',
      can_change_order: 'You can change the order of the photos.',
      uploaded_images: 'Uploaded photos',
      max_size_image: 'The {name} photo cannot be larger than 8MB',
      order_changed: 'Order editing successful!',
      confirm_deleted: 'Delete photos',
      confirm_deleted_text: 'Are you sure you want to do this? This operation cannot be undone? ',
    },
    lang: {
      choose: 'Language Selection'
    }
  },
  profile: {
    edit: 'Edit profile'
  },
  premium: {
    is: 'Premium',
    account: 'Premium account',
    buy: 'Your premium',
    see_with: 'View From',
    PREMIUM: 'PREMIUM',
    to: 'Your account is premium to',
    see_benefits: 'See premium benefits',
    hide: 'Hide',
    benefits: 'Benefits',
    free: 'Free',
    no_ads: 'No Ads',
    be_higher: 'Display above <span class="is-hidden-mobile">other users</span>',
    ukraine: `🇺🇦 100% of payments made by our users in the period 05/03/2022 will be donated until further notice <a
href="https://pomagam.pl/solidarnizukraina" target="_blank" rel="nofollow">🇺🇦 "Solidarni z Ukrainą"</a>
organized by the <a href="https://pomagam.pl/" target="_blank" rel="nofollow">pomagam.pl</a>foundation.
Documentation of this initiative will be available on our profile on <a
href="https://www.facebook.com/jatyportalrandkowy" target="_blank" rel="nofollow">facebook</a>. We encourage you to
like 🇺🇦`,
    see: 'View',
    more_with: 'More benefits from',
    expires: 'You are premium to'
  },
  like: {
    hearts: 'Hearts',
    sent: 'Sent',
    received: 'Received',
    you_didnt_sent_to_anyone: 'You haven\'t sent a heart to anyone yet',
    go_to_propositions_to_like: 'Go to the suggestions and connect with people who match your preferences.',
    linking_description: 'Here you can see the people you sent hearts to, in order from the newest hearts.',
    nobody_sent_you: 'Nobody has sent you a heart yet'
  },
  pair: {
    yours: 'Your couples',
    page_description: 'Here you see people you like and they are liking you',
    no: 'You don\'t have any couple yet'
  },
  delete_request: {
    title: 'Delete account',
    description: `
    <p class="mb-2">When deleting an account, you are deleting:</p>
    <ul class="mb-2 dotted">
      <li>your data,</li>
      <li>your photos,</li>
      <li>your message history,</li>
      <li>your subscription.</li>
    </ul>
    <p class="mb-2">We do not email the process, so:</p>
    <ul class="dotted mb-2">
      <li><b>No</b>you will receive notifications of account deletion.</li>
      <li><b>No</b>you will receive notifications when request was sent.</li>
      <li><b>No</b>you will receive notifications of additional questions.</li>
    </ul>
    <p class="has-text-danger delete-text mb-2">This process cannot be undone.</p>
    <p class="mb-2">You will be able to register again.</p>
    <p class="mb-0">If you are sure you want to delete your account, please enter the reason and ask u to delete your account.</p>`,
    reason: 'What did you dislike most about our portal? (mandatory)',
    ask: 'Ask to delete account',
    cant_cancel: 'Remember that this process cannot be undone.',
    yes_delete: 'Yes, ask for account deletion',
    no: 'Cancel',
    last_step: 'Last step',
    press_yes_to_delete: 'Press "Yes" if you want to permanently delete your data',
    permanently: 'Yes, permanently delete account'
  },
  email_confirmation: {
    resend: 'Send again.',
    didnt_received: 'Email not received?',
    go_to_email: 'Go to your e-mail to confirm it',
    mail_sent: 'Mail has been sent',
    already_send_in_10_minutes: 'You asked to resend your email in the last 10 minutes',
    on_mail: 'to mailbox',
    you_she_received: 'got',
    you_he_received: 'got',
    email_sent_from_us: 'an email from us with instructions on how to confirm your account.',
    do_now: 'Confirm your email address!',
    great_last_step: 'Great! The last step!'
  },
  payment: {
    you_save: 'Save',
    mon: 'month',
    choose_one: 'Choose one of the options',
    go_to_p24: 'Go to Przelewy24',
    thanks_title: 'Thank you for paying for the order!',
    thanks_text: 'We will inform you as soon as we get information about your payment.',
    choosen: 'Selected',
    digital: {
      active: 'You don\'t need to do anything else. Your subscription is active.',
      deactived_globally: 'In-app payments disabled.',
      manage: 'Manage subscription',
      active_now: 'Activate now',
      seven_days_free: '7 days free',
      with_subscription: 'subscription',
    },
  },
  alert: {
    is: 'Notifications',
    description: 'You can see a list of notifications that allow you to control what\'s new in your account.',
    read_all: 'Mark all read',
    no_new: 'You have no new notifications',
    nothing: 'Nothing interesting is happening',
    see_all: 'See all notifications',
    one_more_step: 'One more step',
    new: 'New',
    add_more_info_about_you: 'Complete information about yourself',
    you_are_premium: 'You are premium',
    your_account_is_premium: 'Your account has been extended',
    you_are_pair: 'You are a couple'
  },
  premium_box: {
    twa: `
     <span class="has-text-primary">Premium</span>for 7 days for FREE! Then&nbsp;<span
              class="has-text-danger">50&#37;</span>&nbsp; discounts.`,
    left: 'Left',
    countdown_text: `<span class="has-text-primary">Premium</span> for <span class="countdown-price">29.99 & nbsp; PLN</span>
             <span class="countdown-promo-price has-text-danger">69.99 & nbsp; PLN</span>
              for 12 months!<br>`,
    text: `JaTy becomes <span class="has-text-primary">free</span>for everyone! From March 5th
           2022 premium has only two benefits: "no ads" and "being slightly higher in the results
           search". The rest of the functionality becomes free!<br><br>🇺🇦 100% of payments made by ours
           users in the period 05.03.2022 until further notice, we will donate to a fundraiser
          <a href="https://pomagam.pl/solidarnizukraina" target="_blank" rel="nofollow">🇺🇦"Solidarity with Ukraine"</a>
           organized by the<a href="https://pomagam.pl/" target="_blank" rel="nofollow">Pomagam.pl</a>foundation.
           Documentation of this initiative will be available on our profile on <a
           href="https://www.facebook.com/jatyportalrandkowy" target="_blank" rel="nofollow">facebook</a>.
           We encourage you to like 🇺🇦`
  },
  city_chooser: {
    loading: 'Loading',
    no_results: 'No results'
  },
  home: {
    h1: 'Free Dating Site',
    go_for_jaty: 'Go to JaTy',
    login: 'Login',
    register: 'Registration',
    thousands_of_users: 'Thousands of users',
    every_day_new_users: 'New users every day!',
    totally_free: 'Totally free',
    all_features_free: 'All features are free!',
    only_real_accounts: 'Only real accounts',
    accounts_constantly_verified: 'Verified on a regular basis!',
    chat_graphic_section_h1: 'JaTy is really free dating site',
    chat_graphic_section: `
          <p class="mb-3">
            JaTy is a real chance to find a true love.
            Do you know that every second couple in Europe meet via the Internet? People are looking for love, they need
            it. No matter if you are a shy person or social butterfly, you always have a lot on
            the Internet a better chance to meet your attractive other half who will accompany you at any time
            Your life.
          </p>
          <p>
            Our activity is aimed at people who dream of meeting someone wonderful and exceptional.
            Thanks to all the necessary features, such as chat, you get everything you need to start searching.
          </p> `,
    free_heading: 'JaTy becomes <strong class="has-text-primary">free</strong> forever',
    free_heading_text: 'Did you know that premium is only two things "no ads" and "showing slightly higher in search results". The rest of the functionality becomes free and available to every user!',
    online_to_live_heading: 'Online dating turns into live dating',
    online_to_live: 'JaTy is both an application and a dating site that will allow you to build long-term relationships, but also meet friends to go for a drink with someone or to the cinema. It is aimed at all lonely people, perhaps a bit shy, who need help in what is particularly difficult nowadays - finding love and friendship.',
    we_will_help_you_for_free_heading: 'JaTy will help you find love for free!',
    we_will_help_you_for_free: 'Would you like to meet your love today? Are you feeling lonely? Are you single or single who would like to meet someone? If you answered yes to any of these questions, it means that the JaTy dating website and app is for you. See how true love can change your life!',
    app_heading: 'JaTy Dating App',
    app: 'JaTy is not only a dating website but also an application available directly for Android in the Google Play store.',
    faq_title: 'JaTy Free Online Dating - Frequently Asked Questions (FAQ)?',
    faq: `<h3 class="home-faq-title">Is JaTy Dating Site really free?</h3>
            <p>
              YES. The optional premium is only responsible for appearing slightly higher in search results,
              and the portal does not display advertisements.
            </p>
            <p class="mt-1">Therefore, all available portal functions are available for free.</p>
            <h3 class="home-faq-title mt-5">Why is JaTy free?</h3>
            <p>
              The answer to this question why our website is available for free is very simple - we want to give it to you
              a chance to find true love. Therefore, we will continue to focus on the lack of vulgar content,
              and giving you all the tools you need for online dating.
            </p>
            <h3 class="home-faq-title mt-5">Who will be the people I meet?</h3>
            <p>
              JaTy brings together people from all over the world who are looking for their love.
              We attach great importance down this to there was no so-called fakes, i.e. false accounts,
              set up by dishonest users.
              We do not make any guarantees that our users will be real, but we have the best companion of the real ones
              accounts, from all dating sites.
            </p>
            <h3 class="home-faq-title mt-5">Is registration difficult?</h3>
            <p>
              Of course not <a href="{register_url}">registration</a> JaTy online dating is trivially simple and
              takes seconds.
            </p>
            <h3 class="home-faq-title mt-5">How do dating sites work?</h3>
            <p>
              Different solutions are seen on different portals, but the general goal is always the same - to find
              love.
            </p>
            <h3 class="home-faq-title mt-5">Is JaTy only available to adults?</h3>
            <p>
              Yes, but not because of the controversial content posted by users. JaTy is not a portal
               down seeking earnings or scamming, and our goal and mission is to help people find the real thing love.
            </p>
            <h3 class="home-faq-title mt-5">How we take care of your data</h3>
            <p>
              Nobody registered on the portal or in the application will see you or any of your data there.
              Portal JaTy applies very high security standards so that our users can remain anonymous
              in outside the portal and the app.
            </p>`,
    safe_guarantee_title: 'Security guarantee',
    ssl: 'SSL Certificate',
    spam_filter: 'Spam Filter',
    fake_control: 'Fake account control'
  },
  meta: {
    home: 'JaTy is a free dating site and app to help you find your love. Simple, fast and free registration will allow you to start dating now! Love is waiting for you even today! ',
    login: 'Login - JaTy online dating is a dating portal and application, thanks to which you will find your love. Simple, fast and free registration will allow you to start dating now! Love is waiting for you even today! ',
    contact: 'Contact - JaTy Online Dating is a dating site and application that will help you find your love. Simple, fast and free registration will allow you to start dating now! Love is waiting for you even today! ',
    logout: 'Log out - JaTy Online Dating is a dating site and application that will help you find your love. Simple, fast and free registration will allow you to start dating now! Love is waiting for you even today! ',
    register: 'Register - JaTy Online Dating is a dating site and application that will help you find your love. Simple, fast and free registration will allow you to start dating now! Love is waiting for you even today! ',
    redirect: 'Find Love Online - JaTy Online Dating is a dating site and application that will help you find your love. Simple, fast and free registration will allow you to start dating now! Love is waiting for you even today! ',
    fast_login: 'Quick login - JaTy Online Dating is a dating site and application that will help you find your love. Simple, fast and free registration will allow you to start dating now! Love is waiting for you even today! ',
    login_layout: 'JaTy Online Dating is a dating site and application that will help you find your love. Simple, fast and free registration will allow you to start dating now! Love is waiting for you even today! ',
    regulamin: 'Terms and Conditions - JaTy Online Dating is a dating website and application, thanks to which you will find your love. Simple, fast and free registration will allow you to start dating now! Love is waiting for you even today! ',
    polityka_prywatnosci: 'Privacy Policy - JaTy Online Dating is a dating portal and application, thanks to which you will find your love. Simple, fast and free registration will allow you to start dating now! Love is waiting for you even today! ',
    cookies: 'Cookies policy - JaTy online dating is a dating website and application, thanks to which you will find your love. Simple, fast and free registration will allow you to start dating now! Love is waiting for you even today! '
  },
  title: {
    home: 'JaTy - Free dating site - Dating site',
    login_layout: 'JaTy - Free Online Dating - Dating Portal and App',
    help: 'Help Center - JaTy Online Dating',
    welcome: {
      index: 'Hello - JaTy Online Dating',
      about: 'Hello - JaTy Online Dating',
      images: 'Photos - JaTy Online Dating'
    },
    users: {
      index: 'Suggestions - JaTy Online Dating',
      liked: 'Hearts - JaTy Online Dating',
      liking: 'Hearts - JaTy Online Dating',
      _slug: '- JaTy Online Dating'
    },
    'current-user': {
      'verify-email': 'Validate your email',
      payments: {
        digital: 'Premium - JaTy Online Dating',
        index: 'Premium - JaTy Online Dating',
        thanks: 'Thank you - JaTy Online Dating'
      },
      edit: {
        'delete-request': 'Account deletion - JaTy Online Dating',
        images: 'Photos - JaTy Online dating',
        index: 'Basic information - JaTy Online Dating',
        lang: 'Language selection - JaTy Online Dating',
        'notification-preferences': 'Notification preferences - JaTy Online Dating',
        password: 'Change password - JaTy Online Dating',
        preferences: 'Preferences - JaTy Online Dating',
        profile: 'More about me - JaTy Online Dating'
      }
    }
  },

  terms_of_use: `<h2> General Terms of Service Provision (hereinafter referred to as" General Terms ") </h2>
    <p> The General Terms and Conditions of the Jaty Service define the rules of using the Jaty service (hereinafter referred to as the "Jaty Service"), operated by Allset Piotr Zatorski, 8a Orkana 25-548 Kielce, NIP: 9591864486, REGON: 381246125, hereinafter referred to as "Jaty". </p>
    <p> Upon registration on the Website, the Customer declares and warrants that he has read and accepted the General Terms and Conditions. The general terms and conditions therefore form an integral part of the contract. </p>
    <p> The general conditions apply to all customers. </p>
    <p> People over 18 years of age may use the Jaty Service. </p>
    <h3> 1. Subject of the contract </h3>
    <p> (1) The subject of the contract concluded between the Customer and the Website may be both free and paid services. Registration on the Jaty Website is free of charge, additionally paid services are provided only at the request of the Customer after paying the appropriate fee. Before using the paid service, the Customer is informed that the service is payable and what is its cost. Jaty Service offers the Client, among others, the following benefits (see also point 3. "Jaty's service and the scope of services"): </p>
    <p> Customer introduction to the Service database and data management; </p>
    <p> Enabling contact with other Customers of the Jaty Service. </p>
    <p> (2) The subject of the contract between the Customer and the Jaty Service Center is neither marriage matching, matrimonial mediation, nor activity aimed at establishing a partnership or marriage. </p>
    <p> (3) Jaty's website offers its services solely for private purposes. By registering for the Jaty Service, the Customer undertakes to use the Jaty Service only for private purposes, not for profit or professional purposes. </p>
    <p> (4) To use the Jaty Website, it is necessary to have devices that allow the use of the Internet, an e-mail mailbox and a web resource browser that allows displaying WWW pages. </p>
    <h3> 2. Using the Service and concluding a contract </h3>
    <p> (1) The use of the Jaty Service requires registration. During registration, the Customer chooses a username under which other customers of the Service will be visible, and a personal password. Alternatively, the Jaty Service may assign to the Customer the so-called "Code". The e-mail address provided is used only for contacting the Service of Jaty with the Client and is not passed on to other Clients of the Service of Jaty. </p>
    <p> (2) By registering, the Customer accepts these General Terms and Conditions and declares that he or she is of legal age, i.e. at least 18 years of age. </p>
    <p> (3) The contract between the Customer and the Jaty Service Center is concluded upon the Customer's registration. These General Terms and Conditions apply to this agreement. Registration is free. </p>
    <p> (4) At the time of ordering a paid service, the registered customer receives the so-called "Premium account", to which the General Terms and Conditions also apply. The Customer is informed about the payment of the benefit and the applicable prices and terms of payment. The contract for paid services is concluded when the Customer accepts the obligation to pay on the Website by clicking on the acceptance field. </p>
    <p> (5) The customer declares that the data provided during registration, ordering and payment are true. </p>
    <p> (6) Jaty has the right to block access to the website if the customer uses the website and / or website in breach of legal provisions; violating one or more rights of third parties or breaching the current obligations of the client - see further details. 6. Customer Responsibilities. Blocking access or removing the account for the reasons indicated above does not release the Customer from the obligation to pay for the ordered service. In the event of a serious breach by the Customer of the provisions of the General Terms and Conditions or the contract, the Jaty Service Center reserves the right to charge a contractual penalty in the amount of up to (EUR 350.00). Demand by the Website of the amount of compensation exceeding the amount of the contractual penalty is allowed. </p>
    <h3> 3. Jaty service and scope of services </h3>
    <p> (1) The provision of services by the Jaty Website begins with the registration and filling in the profile data. Filling out consists of a series of questions to which the Client answers. </p>
    <p> (2) The Customer's responses and further personal data are entered into the database of the Jaty Website and compared there with the data of other Customers of the Jaty Website. The comparison is made on the basis of a mathematical algorithm (computational operation) that allows to determine the compliance of two customer profiles. </p>
    <p> (3) As a result of comparing the Customer's data with the data of other Customers of the Jaty Website, the Customer receives a list of those Customers who best suit him, in the form of anonymous short profiles. </p>
    <p> (4) The Client may then contact other Jaty Service Clients proposed to him. Direct contact between Jaty's Service Clients is possible only with mutual consent of the Clients. Such mutual consent may be obtained in such a way that a given Customer sends his anonymous abridged profile to the selected Customers (so-called "contact"). The other Customer may then decide whether he wants to respond or not. Contacting within the meaning of the General Conditions also when the Customer agrees to contact proposals from another Customer.Communication between Customers takes place via the internal communication system of the Jaty Website. </p>
    <p> (5) The possibility of establishing contacts and transmitting messages is free, unless agreed otherwise during the conclusion of the contract. </p>
    <p> (6) A month should be understood not as a calendar month but a period of full 31 days. </p>
    <p> (7) Before getting access to paid benefits, the customer is clearly informed about the existing payment obligation. The costs incurred, the scope of services and the applicable payment terms are explained as part of the ordering procedure for the respective paid service. </p>
    <p> (8) Jaty Website does not guarantee that the data provided by the Customers is true. </p>
    <p> Jaty Website does not guarantee that the contact will be made effective during the term of the contract. </p>
    <p> (9) Basically, the service provided by Jaty is available 24/7. Availability is estimated at 95% on an annual basis. The above value does not include interruptions related to the maintenance or update of the software or caused by factors that are not dependent on Jaty, such as unavailability of Internet services due to the fault of third parties or due to force majeure. Jaty recommends its clients to use the latest (browser) technology and to use appropriate browser settings (Java script, cookies and pop-ups activation) for better use of Jaty. Where outdated or non-standard technology is used, Jaty cannot guarantee the proper operation of all services and features. </p>
    <p> (10) The customer acknowledges and agrees that all content submitted by him to the Jaty website is available for viewing by all customers on his list of featured contacts. This applies regardless of whether other customers have registered with the site operated by Jaty or through other websites / applications or partners cooperating with Jaty. In this way, customers can benefit from a central database maintained by Jaty over services that are available through various domains and websites / applications, seeing these users in their recommended contacts. </p>
    <h3> 4. Mutual notifications made by the Customer and Jaty Service </h3>
    <p> (1) Messages from Jaty to the client are sent through the screens displayed after "Logging in", i.e. when the client has entered his username (or assigned Code) and password, or to the e-mail address provided by the client during registration or currently stored by the customer in their profile. </p>
    <p> (2) Messages from the Customer to the Jaty Service Center are sent by e-mail to the e-mail address provided on the Jaty website (e.g. kontakt@jaty.pl), by letter or fax. When contacting Jaty by letter or fax, for unique authorization and protection against misuse, it is absolutely imperative that the Customer provide at least their registered e-mail address on the Jaty website, the country in which they use Jaty's services and the username (or mu code) in correspondence with Jaty. When contacting Jaty by e-mail, for authorization and protection against abuse, it is absolutely imperative that all e-mails sent by the Client to Jaty are sent from the e-mail address provided by the Client during registration or currently stored by The client in his profile. </p>
    <h3> 5. Liability of the Jaty Website </h3>
    <p> (1) The Jaty website is not responsible in any way, within the limits consistent with applicable law, for the materials and data provided and published on the Jaty website by customers and for their truthfulness, reliability and authenticity. The Jaty website is not responsible for the correctness of the data provided by the Jaty website customers in the personality test. The Jaty website is therefore not responsible for the correctness of the test result and the comparison of customer profiles, because the preparation of the test result and comparison of customer profiles are based on the data provided. If we learn that the client is in breach of legal regulations; violates one or more of the rights of a third party or breaches the customer's existing obligations under clause 6, then clause 2.6 will apply. </p>
    <p> (2) Direct contact between Jaty's Service Clients takes place only with mutual consent (see section 3 "Jaty's Service and the scope of services"). Jaty's Service is therefore not liable if no contact is made during the term of the agreement. Jaty Service makes every effort to mediate in contacts between Jaty Service Customers by ensuring appropriate technical conditions. p>
    <p> (3) Jaty's website is not responsible for the misuse of data and information by others, because despite the express prohibition (see point 6 "Customer's obligations"), customers may use the Jaty's website in violation of the law. when the information that the Customer himself has made available to a third party is used unlawfully by a third party. </p>
    <p> (4) Jaty does not guarantee that the service will be error-free at all times, ie that it will be constantly available without interruption. In particular, Jaty is not responsible for failures of technical equipment or the quality of access to services due to force majeure or events beyond Jaty's control (e.g. failure of communication networks). </p>
    <p> (5) Jaty is not responsible for the unlawful acquisition of customer personal data by third parties, e.g. in the form of access to the database by hackers. </p>
    <p> (6) For losses other than those resulting from the above-mentioned reasons, Jaty will be liable only in cases of intentional or gross negligence on the part of its management, employees or contractors, and such liability is proportional to the other reasons that contributed to the loss. </p>
    <p> (7) Jaty is only liable for slight negligence in the event of damage to life, body or health or in the event that it has breached essential contractual obligations. Liability in the latter case is limited to the foreseeable, direct losses typical of this type of contractual relationship. </p>
    <p> (8) Contractual liability is otherwise excluded. </p>
    <h3> 6. Customer Responsibilities </h3>
    <p> (1) The customer is solely responsible for all data provided by him. She/he ensures that the data provided by him is correct. The customer undertakes not to provide the data of third parties as his own. Providing false data violates the provisions of the contract and the General Terms and Conditions and may require the Jaty Website to take appropriate legal steps. </p>
    <p> The customer undertakes to keep the data provided on the Jaty Website, in particular contact details and payment details, up-to-date. </p>
    <p> The Jaty website is entitled to check the content of the profile, including the posted photos, in terms of their compliance with the law, General Conditions and the contract. Jaty's website is entitled to reject, change and even delete the content of the data. </p>
    <p> (2) The client should not provide information that is not explicitly requested of her/him. This applies in particular to name, home address, e-mail address, website address, telephone number, fax number. </p>
    <p> (3) The Customer is obliged to treat confidential data (in particular names, home addresses, e-mail addresses, website addresses, telephone numbers and faxes) regarding e-mail, messages and notifications received from other Customers of the Jaty Website and to not to disclose this data to third parties without the consent of the data subject. It is forbidden to disclose the data of persons who are not Clients of the Jaty Website. </p>
    <p> (4) The Client guarantees that he will not conduct commercial activities via the Jaty Website and undertakes not to send messages to other Clients of the Jaty Website for purposes other than personal communication. In particular, the Customer will not advertise or offer goods or services. The customer also undertakes not to send the so-called "Spam" etc. This includes, but is not limited to, the use of the video chat feature, which is available in some countries as part of the Premium Account. </p>
    <p> (5) The Client undertakes not to use the Jaty Service for the purpose of: </p>
    <p> sending defamatory, scandalous, pornographic or illegal material; </p>
    <p> threats, harassment, defamation etc; </p>
    <p> making promises or demands for cash benefits; </p>
    <p> violate the rights of third parties. </p>
    <p> The customer also undertakes to: </p>
    <p> not to introduce data containing viruses (infected software) to the Website; </p>
    <p> not to enter data containing software or other materials protected by copyright or industrial property rights to the Jaty Website, unless the Customer is the owner of a given right or has the necessary consent of the holder of the right to use the software or materials; </p>
    <p> not to use the Jaty Website in a way that may adversely affect the availability of the Jaty Website offer to other Customers; </p>
    <p> not to interfere with the computer system of other Clients, in particular not to take illegal actions related to the e-mail of other Clients. </p>
    <p> (6) The breach by the Customer of one of the above-mentioned obligations may lead to the termination of the contractual relationship by the Jaty Website, which is related to the immediate blocking of access to the Jaty Website. All claims by the Jaty Service Center against the Customer remain valid (see point 2 "Use of the Jaty Service and conclusion of the contract" (6)). </p>
    <p> (7) If the Customer uses the data on the Jaty Website for commercial purposes, the Jaty Website may demand the payment of a contractual penalty in the amount of (EUR 2,500, -) for each single violation. Demand by the Jaty Service for compensation exceeding the amount of the contractual penalty is allowed. </p>
    <p> (8) The customer undertakes to release the Jaty Website from any effects of lawsuits, claims for damages, losses and receivables that arise in connection with his registration on the Jaty Website and use of it. The customer indemnifies the Jaty Service from all civil liability and from all liabilities and damages that may arise as a result of claims arising from slander or insult, and from violating the rights of other customers. </p>
    <p> (9) The client should receive incoming messages and reply to them regularly and, if necessary, archive them on his own computer or other data carrier. </p>
    <p> Jaty's website is entitled, without the consent and notification of the Client, to delete messages saved in the Client's account after twelve (12) months from sending or receiving the message by the Client. </p>
    <p> The Jaty Service Center is entitled, without the consent and notification of the Customer, to delete the account of the Customer who does not use the free Jaty Service for a period of at least six (6) months. First of all, this action serves to protect the Customer's data, because the Jaty Service assumes that after this deadline, the Customer is no longer interested in storing his data, and secondly, this action is to clean the Jaty Service's database of inactive customers, which is tantamount to improving the quality of the service offered. . </p>
    <h3> 7. Information on data protection, data processing and cookies </h3>
    <p> Our personal data protection can be found here: <a href="/polityka-prywatnosci"> https://www.jaty.pl/polityka-prywatnosci </a> </p>
    <p> 8. Termination of the contract, extension of the contract and suspension of the service provision </p>
    <p> (1) Termination </p>
    <p> The customer may conclude a paid contract with the Jaty Service (payable contract) and a free contract (free contract). The free contract is created upon the Customer's registration on the Jaty Website. </p>
    <p> The customer is entitled to terminate a free and paid contract at any time without giving any reason. Membership is terminated by filling out the contact form in the "Help" menu on the Jaty Service website. </p>
    <p> Jaty's website is entitled to terminate the free contract with a 14-day notice period. Notwithstanding the foregoing, the Jaty Service is entitled to terminate the contract at any time in the cases specified in these General Terms and Conditions and in the contract. </p>
    <p> (2) Extension of contract </p>
    <p> The contract for the performance of a paid service is automatically extended for the period agreed in the order (e.g. 3, 6 or a maximum of 12 months), unless the Customer terminates the contract in writing at least 14 calendar days before the end of the contractual period - unless a different date has been agreed - by providing the name and surname, country in which he uses the Jaty Website, e-mail address registered on the Jaty Website and the user's name (or a code assigned to him). See paragraph 1 of this section "Termination of contract", paragraph "Termination of a contract for pecuniary interest". </p>
    <p> In the case of trial subscription, advertising campaigns, etc., there may be exceptions to this rule. In each case, the Customer is informed about the duration of the trial subscription, the possibility of its termination and its possible automatic extension. </p>
    <p> When ordering a paid service, the Customer is informed about the duration of the contract, the date of commencement of the contract, upon receipt of the order confirmation. </p>
    <p> Jaty will inform the User via e-mail sent to the e-mail address provided during registration about the upcoming extension of the subscription, before its renewal. </p>
    <p> (3) Service Cessation </p>
    <p> Jaty's website has the right to partially or completely discontinue the services offered. Jaty will inform the client about the planned cessation of the service and its scope in advance at least 14 days before the partial or complete cessation of the service. </p>
    <p> Customers who have paid but have not fully used their rights to use the Jaty website at the time of stopping the services will receive a proportional refund for the unused rights. </p>
    <p> 9. Complaint, delay in payment </p>
    <p> (1) Any complaints may be submitted by the Customer via e-mail or in writing to the addresses below: </p>
    <p> Jaty Customer Service ul. Orkana 8a 25-548 Kielce </p>
    <p> or E-mail: jaty@jaty.pl </p>
    <p> (2) Objections to the amount charged from the account or the costs indicated in the invoice should be reported to the Jaty Service Center and justified (for example, a calculation error) in writing within six (6) weeks from the collection of the disputed costs from the account or receipt of the invoice . </p>
    <p> In the event of an eligible objection, the amount claimed is either refunded to the Customer or an extension of the performance against payment ("Premium Membership") is offered. The extension of the performance corresponds proportionally to the period to which the Customer has lodged the entitled objection. </p>
    <p> The customer also has the right to appoint the Jaty Service Center a period of five working days to restore the proper performance of the service. If the reason for the complaint is not removed after this deadline, the customer has the right to terminate the contract. The reimbursement of the unused balance is made in accordance with clause 8 "Termination of the contract, extension of the contract and suspension of service provision", paragraph 1. </p>
    <p> Insignificant difficulties in using the Website do not entitle to lodge a complaint. An insignificant difficulty in using the paid benefit occurs when the period in which the Customer cannot use the paid benefit does not exceed two consecutive days. </p>
    <h3> 10. Copyright </h3>
    <p> (1) All rights to the Jaty Website's programs, procedures, software content, technologies, brands, trade names, inventions, materials and any other intellectual property rights that are part of the Jaty Website are vested solely by the Jaty Website. The use of any programs and their content, materials, brands and trade names is allowed only for the purposes indicated in these General Terms and Conditions. Reproduction of programs, benefits, procedures, software, technologies, brands, trade names, inventions and materials belonging to the Jaty Website is prohibited, unless expressly permitted by the Jaty Website. </p>
    <h3> 11. The right to withdraw from the contract, Information on the exercise of the right to withdraw from the contract </h3>
    <p> Information on the exercise of the right of withdrawal </p>
    <p> Right of withdrawal </p>
    <p> The customer has the right to withdraw from the contract within fourteen days without giving any reason. </p>
    <p> The withdrawal period is fourteen days from the date of the conclusion of the contract. </p>
    <p> In order to withdraw from the contract, a clear statement (e.g. by letter sent by post, or by e-mail) about the decision to withdraw from the contract. In order to meet the deadline for withdrawing from the contract, it is enough to inform about the exercise of the right of withdrawal before the expiry of this period. </p>
    <p> Consequences of withdrawal </p>
    <p> In the event of withdrawal from the contract, we will refund all payments received, including delivery costs (with the exception of additional costs resulting from the choice of a delivery method other than that offered by us, the cheapest standard delivery), immediately and at the latest within fourteen days from the date of receipt by us information about your withdrawal from the contract. The same method of payment used for the original transaction will be used for the reimbursement of the payment, unless otherwise expressly agreed. However, in no event will any fees be charged for the refund of the payment. </p>
    <p> If we have been obliged to commence the provision of services within the period to withdraw from the contract, then you undertake to pay the fee for the services rendered until notification of the exercise of the right to withdraw from the contract, proportionally to the entire scope provided for in the contract for the provision of services. </p>
    <h3> 12. Change of the General Conditions </h3>
    <p> (1) The General Conditions contained in the Regulations may be amended if they do not affect the essential provisions of the contractual relationship and that such a change is necessary in order to adapt issues not provided for at the time of the conclusion of the contract, which would significantly breach the balance of the contractual relationship should they remain unaccounted for. "Essential provisions" are in particular those relating to the type and scope of the contractually agreed services and their duration, including the provisions governing their termination. Further modifications or additions to the Regulations may take place if it is necessary to solve difficulties related to the performance of the contract, caused by gaps arising after the conclusion of the contract. In particular, this may be the case when the legislation is changed and it affects one or more points of the Regulations. </p>
    <p> (2) The specifications for the services may be changed if there is a justified need, provided that these changes do not result in an objectively less favorable situation for the customer (e.g. the need to service or improve the functionality of the website) than it was caused by the specifications of services in force at the time of concluding contract and they do not differ significantly from the previous specifications. A justified need to make changes occurs when technical innovations are available on the market or when third parties, from whom Jaty obtains advanced services necessary for the proper functioning of the website, change the scope of their services. </p>
    <p> (3) The customer must be informed of the changes to the Regulations and the specifications of the services in accordance with paragraph 12. (1) and (2) at least six (6) weeks before their entry into force. Upon their entry into force, the customer should have a special right to terminate the contract. If the customer does not terminate the contract in writing within six (6) weeks of receiving notification of the changes, the changes will become part of the contract when they come into effect. The customer will be expressly notified of this effect via a notification of the changes. </p>
    <p> 13. Others </p>
    <p> (1) Jaty has the right to commission third parties, who are its service providers and contractors, to provide part or all of the scope of the services provided, provided that this does not cause any disadvantages to the client. </p>
    <p> (2) If individual provisions of these Terms and Conditions or the contract are or become invalid, the validity of the remaining provisions shall remain binding on the parties. </p>
    <p> (3) The applicable law in the relationship between the Customer and Jaty's Website is Polish law, subject to the mandatory provisions of the EU Regulation No. 593/2008. </p>
    <p> (4) Jaty does not participate in the EU Online Dispute Resolution (ODR) system and is not obliged to do so: https://ec.europa.eu/consumers/odr/ </p>
    <p> (5) The owner of the Jaty website is Allset Piotr Zatorski ul. Orkana 8a 25-548 Kielce NIP: 9591864486 </p>`,

  privacy_policy_content: `<h2> Privacy policy of www.jaty.pl </h2>
    <p> General information </p>
    <p>1. This Privacy Policy sets out the rules for the processing and protection of personal data provided by Users in connection with their use of the dating website services through the Website.</p>
    <p>2. The administrator of personal data contained on the website is Detektyw Robert Zatorski with headquarters in ul. Wesoła 51 pok. 702, (VII-piętro)25-363 Kielce NIP: 735 126 50 58.</p>
    <p>3. In the interests of the security of the data entrusted to us, we have developed internal procedures and recommendations to prevent disclosure of data to unauthorized persons. We control their performance and constantly check their compliance with the relevant legal acts - the Act on the protection of personal data, the Act on the provision of electronic services, as well as all types of executive acts and Community legislation.</p>
    <p>4. Personal data are processed on the basis of the consent expressed by the User and in cases where the law authorizes the Administrator to process personal data on the basis of legal provisions or to implement the contract concluded between the parties.</p>
    <p>5. The website performs the functions of obtaining information about users and their behavior in the following way: through information entered voluntarily in forms by collecting "cookies" <a href="/cookies"> [see cookie policy]. </a></p>
    <p>6. The website collects information voluntarily provided by the user.</p>
    <p>7. The data provided in the form are processed for the purpose resulting from the function of a specific form, e.g. to process the information contact</p>
    <p>8. Personal data left on the website will not be sold or made available to third parties, in accordance with the provisions of the Personal Data Protection Act.</p>
    <p>9. The data contained in the form may be viewed by the person who placed it there. This person also has the right to modify and stop processing his data at any time.</p>
    <p>10. We reserve the right to change the privacy policy of the website, which may be affected by development Internet technology, possible changes to the law in the field of personal data protection and the development of our website internet. We will inform you about any changes in a visible and understandable way.</p>
    <p>11. Links to other websites may appear on the Website. Such websites work regardless of the Website and they are not supervised in any way by jaty.pl. These pages may have their own privacy policies and regulations, which we recommend that you read. If in doubt about any of the provisions of this privacy policy, we are available - ours the data can be found in the tab - CONTACT.</p>
    <p>12. The user may at any time request the deletion of all his data related to the account by contacting us via the contact form is available in the customer panel in the "Help Center> Contact us" section, or by contacting us at at <a href="https://jaty.pl/kontakt"> https://jaty.pl/kontakt </a></p>`,

  cookies_content: `<h2> The cookie policy of jaty.pl </h2>
    <p>
      1. The "cookie" peaks should be understood as IT data stored in users' end devices,
      intended for the use of websites. In particular, these are text files containing the name of the page
      website from which they come from, their storage time on the end device and a unique number.
    </p>
    <p> 2. Cookies are intended for using the website pages. The operator uses these files for: <br>
      the possibility of logging in and maintaining the user's session on each subsequent page of the website <br>
      adjusting the content of the website to the individual preferences of the user, primarily these files
      recognize his device to display the <br> page according to his preferences
      to create anonymous statistics with the exception of the possibility of user identification.
    </p>
    <p>
      4. Cookies used by the website operator's partners, in particular users
      website, are subject to their own privacy policy.
    </p>
    <p>
      5. In the interests of the security of the data entrusted to us, we have developed internal procedures and recommendations that they have
      prevent disclosure of data to unauthorized persons. We control their performance and constantly check their compliance
      with the relevant legal acts - the Act on the protection of personal data, the Act on the provision of road services
      electronic, as well as all kinds of implementing acts and acts of Community law
    </p>
    <p>
      6. By default, the software used for browsing websites allows files to be placed by default
      cookies on the User's end device. These settings may be changed by the User in such a way as
      to block the automatic handling of "cookies" in the web browser settings or to inform about them
      each time it is sent to the user's devices.
    </p>
    <p>
      7. The Website Users can change their cookie settings at any time. Specific
      information on the possibilities and methods of handling cookies is available in the software settings (browser
      website).
      Examples of editing options in popular browsers:
      Mozilla Firefox: <a href="www.support.mozilla.org/pl/kb/ciasteczka" target="_blank" rel="noreferrer nofollow">www.support.mozilla.org/pl/kb/ciasteczka </a><br>
      Internet Explorer: <a href="www.support.microsoft.com/kb/278835/pl" target="_blank" rel="noreferrer nofollow">www.support.microsoft.com/kb/278835/pl</a><br>
      Google Chrome: <a href="www.support.google.com/chrome/bin/answer.py?hl=pl&answer=95647" target="_blank" rel="noreferrer nofollow">www.support.google.com/chrome/bin/answer.py?hl=pl&answer=95647</a><br>
      Safari: <a href="www.safari.helpmax.net/pl/oszczedzanie-czasu/blokowanie-zawartosci/" target="_blank" rel="noreferrer nofollow">www.safari.helpmax.net/pl/oszczedzanie-czasu/blokowanie-zawartosci/</a><br>
    </p>
    <p>
      8. The website operator informs that changes to the settings in the user's web browser may be impossible
      correct operation of the Websites.
    </p>`,

  // shame
  'Normalna': 'Normal',
  'Muskularna': 'Muscular',
  'Lekko puszysta': 'Slightly fluffy',
  'Puszysta': 'Fluffy',
  '120 i mniej': '120 and less',
  '211 i więcej': '211 and more',
  'Posiadam dzieci': 'I have children',
  'Chce mieć': 'I want to have',
  'Jeszcze nie wiem': 'I don\'t know yet',
  'Brązowe (piwne)': 'Brown (hazel)',
  'Niebieskie': 'Blue',
  'Szare': 'Gray',
  'Mieszane': 'Mixed',
  'Panna / Kawaler': 'Miss / Mister',
  'Kawaler': 'Single',
  'Panna': 'Miss',
  'Rozwiedziony': 'Divorced',
  'Rozwiedziona': 'Divorced',
  'Wdowiec': 'Widower',
  'Wdowa': 'Widow',
  'Mam żonę': 'I have a wife',
  'Mam męża': 'I have a husband',
  'Rozwódka / Rozwodnik': 'Divorced',
  'Wdowa / Wdowiec': 'Widow',
  'Separacja': 'Separation',
  'Uczeń / Student': 'Studentg',
  'Emeryt': 'Retired',
  'Rencista': 'Pensioner',
  'Niepracujący': 'Not working',
  'Przedsiębiorca': 'Entrepreneur',
  'Usługi': 'Services',
  'Handel': 'Trade',
  'Pracownik biurowy': 'Office worker',
  'Pracownik fizyczny': 'Manual worker',
  'Służba mundurowa': 'Uniformed Service',
  'Służba zdrowia': 'Healthcare',
  'Edukacja': 'Education',
  'Kierownik / Manager': 'Manager',
  'Dyrektor / Prezes': 'Director / President',
  'Działalność artystyczna': 'Artistic activity',
  'Działalność publiczna': 'Public activity',
  'Marketingowiec / PR\'owiec': ' Marketing / PR',
  'Działalność naukowa': 'Scientific activity',
  'Inne': 'Other',
  'Bardzo chcę małżeństwa': 'I want marriage very much',
  'Dopuszczam myśl o małżeństwie': 'I allow the thought of marriage',
  'Nie chcę małżeństwa': 'I don\'t want marriage',
  'Podstawowe': 'Basic',
  'Średnie': 'Medium',
  'Pomaturalne': 'Medium finished',
  'Licencjat': 'Bachelor',
  'Podyplomowe': 'Postgraduate',
  'Wyższe niepełne': 'Higher incomplete',
  'Wyższe': 'Higher',
  'Katolicyzm': 'Catholicism',
  'Prawosławie': 'Orthodoxy',
  'Protestantyzm': 'Protestantism',
  'Świadek Jechowy': 'Jehovah\'s Witness',
  'Buddyzm': 'Buddhism',
  'Hinduizm': 'Hinduism',
  'Islam': 'Islam',
  'Judaizm': 'Judaism',
  'Ateizm': 'Atheism',
  'Nie chcę mówić': 'I don\'t want to say',
  'Inna': 'Other',

  images: {
    hero: '/portal-randkowy/assets/img/hero_en.webp',
    mobile_jaty: '/portal-randkowy/assets/img/mobile-jaty_en.webp',
    chat: '/portal-randkowy/assets/img/chat_en.webp',
    play_badge: '/en/google/play_badge.png'
  }
}
