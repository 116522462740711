import Vue from 'vue'
import { ValidationProvider, ValidationObserver, localize, extend, setInteractionMode } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import pl from 'vee-validate/dist/locale/pl.json'
import en from 'vee-validate/dist/locale/en.json'
import uk from 'vee-validate/dist/locale/uk.json'

extend('required', {
  validate (value) {
    return {
      required: true,
      valid: ['', null, undefined].indexOf(value) === -1
    }
  },
  computesRequired: true
})

extend('city', {
  validate (value) {
    return {
      required: true,
      valid: typeof value.description !== 'undefined' && value.place_id,
    }
  },
})

extend('city', {
  validate (value) {
    return {
      required: true,
      valid: typeof value.description !== 'undefined' && value.place_id,
    }
  },
})

extend('contains_email', value => {
  var emailsArray = value.match(/[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,3})/gi)

  if (emailsArray != null && emailsArray.length) {
    return 'Wiadomość nie może zawierać danych kontaktowych'
  }

  var emailsArray = value.match(/(gmail|hotmail).{0,4}com/gi)

  if (emailsArray != null && emailsArray.length) {
    return 'Wiadomość nie może zawierać danych kontaktowych'
  }

  return true
})

// Add a rule.
Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
  })
})

export function localizeVeeValidate (locale) {
  if (locale === 'pl') {
    localize('pl', {
      ...pl,
      messages: {
        ...pl.messages,
        'confirmed': 'Hasła nie zgadzają się',
        'city': 'Miasto nie jest uzupełnione',
      },
      fields: {
        privacy_policy: {
          'length': 'Musisz zakceptować politykę prywatności'
        },
      }
    })
  } else if (locale === 'en') {
    localize('en', { ...en })
  } else if (locale === 'uk') {
    localize('uk', { ...uk })
  }
}

setInteractionMode('eager')

// Register it globally
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

export default function ({ app }) {
  const locale = app.i18n.locale
  localizeVeeValidate(locale)
}
