export const state = () => ({
  service: null
})

export const mutations = {
  async SET_SERVICE (state, service) {
    state.service = service
  }
}

export const actions = {
  fetchPlayBillingService ({ commit }) {
    console.debug('[servicePlayBilling.js] commit(\'SET_SERVICE\', service)')

    window.getDigitalGoodsService('https://play.google.com/billing')
      .then(service => {
        console.debug('[servicePlayBilling.js] commit(\'SET_SERVICE\', service)', service)
        commit('SET_SERVICE', service)
      })
      .catch(e => {
        this.$sentry.captureException(e)
      })
  },
  async asyncFetchPlayBillingService ({ commit }) {
    console.debug('[servicePlayBilling.js] commit(\'SET_SERVICE\', service)')

    window.getDigitalGoodsService('https://play.google.com/billing')
      .then(service => {
        console.debug('[servicePlayBilling.js] commit(\'SET_SERVICE\', service)', service)
        commit('SET_SERVICE', service)
      })
      .catch(e => {
        this.$sentry.captureException(e)
      })
  },
}
