export const state = () => ({
  searchParameters: {},
})

export const mutations = {
  SET_SEARCH_PARAMETERS (state, searchParameters) {
    state.searchParameters = searchParameters
  }
}

export const actions = {
  fetchSearchParameters ({ commit, state }) {
    if (Object.keys(state.searchParameters).length === 0 && state.searchParameters.constructor === Object) {
      commit('SET_SEARCH_PARAMETERS', {
        city: this.$auth.user.city,
        radius: 50000
      })
    }
  },
  setSearchParameters ({ commit }, searchParameters) {
    commit('SET_SEARCH_PARAMETERS', {
      city: searchParameters.city,
      radius: searchParameters.radius,
    })
  }
}

