const middleware = {}

middleware['isUser'] = require('../middleware/isUser.js')
middleware['isUser'] = middleware['isUser'].default || middleware['isUser']

middleware['isUserOrNot'] = require('../middleware/isUserOrNot.js')
middleware['isUserOrNot'] = middleware['isUserOrNot'].default || middleware['isUserOrNot']

middleware['redirectToLang'] = require('../middleware/redirectToLang.js')
middleware['redirectToLang'] = middleware['redirectToLang'].default || middleware['redirectToLang']

middleware['userFull'] = require('../middleware/userFull.js')
middleware['userFull'] = middleware['userFull'].default || middleware['userFull']

middleware['userVerified'] = require('../middleware/userVerified.js')
middleware['userVerified'] = middleware['userVerified'].default || middleware['userVerified']

export default middleware
