export default {
  login: 'Logowanie',
  login_do: 'Zaloguj się',
  logout: 'Wyloguj się',
  or: 'lub',
  terms: 'Regulamin',
  cookies_policy: 'Polityka cookies',
  privacy_policy: 'Polityka prywatności',
  email: 'E-mail',
  password: 'Hasło',
  success: 'Udało się!',
  comeback: 'Wróć',
  to: 'do',
  login_to: 'logowania',
  something_wrong: 'Przepraszamy coś poszło nie tak',
  ask_for_another_email: 'Poproś o nowego maila',
  send: 'Wyślij',
  find_love_online: 'Znajdź miłość online',
  fast_login: 'Szybkie logowanie',
  hi: 'Cześć',
  woman: 'Kobieta',
  man: 'Mężczyzna',
  continue: 'Dalej',
  cancel: 'Anuluj',
  save_changes: 'Zapisz zmiany',
  premium_for_month: 'Jesteś premium przez najbliższy miesiąc!',
  see_partners_suggestions: 'Zobacz propozycje partnerów',
  kilometer: 'km',
  from: 'od',
  reset_do: 'Resetuj',
  parameters: 'Parametry',
  change_do: 'Zmień',
  city: 'Miejscowość',
  distance: 'Odległość',
  save_parameters: 'Zapisz parametry',
  propositions: 'Propozycje',
  hearts: 'Serduszka',
  your_pairs: 'Twoje pary',
  your_profile: 'Twój profil',
  check_profile: 'Zobacz profil',
  problem: 'Problem',
  are_you_sure: 'Czy jesteś pewien?',
  change_order: 'Zapisz kolejność',
  yes_delete: 'Tak, usuń',
  hide_menu: 'Schowaj menu',
  show_menu: 'Pokaż menu',
  month: 'miesiąc',
  three_months: 'miesiące',
  twelve_months: 'miesiący',
  go_back_home: 'Powrót na stronę główną',
  see_more: 'Zobacz więcej',
  and: 'i',
  close: 'Zamknij',
  get_now: 'Odbierz teraz',
  buy_now: 'Zakup teraz',
  you_are_pair_with: 'Jesteście parą z',
  you_are_offline: 'Wygląda na to, że jesteś offline. <a href="/">Odśwież stronę</a>',
  close_this_window: 'Zamknij to okno',
  not_now: 'Nie teraz',
  not_found: 'Nie znaleziono',
  something_went_wrong: 'Coś poszło nie tak :(',
  homepage: 'Strona główna',
  account: {
    confirmed: 'Twoje konto zostało potwierdzone! Możesz się zalogować!',
    confirmed_already: 'Twoje konto zostało już wcześniej potwierdzone',
    wrong_password: 'Nieprawidłowy login lub hasło',
    cant_login_now: 'Nie można się teraz zalogować',
    login: 'Zaloguj się',
    no_account: 'Nie masz konta?',
    your: 'Twoje konto',
    password: {
      change: 'Zmiana hasła',
      forgot: 'Zapomniałeś hasła?',
      remind: 'Przypomnienie hasła',
      remind_now: 'Przypomnij teraz',
      change_now: 'Zmień hasło',
      changed_successfully: 'Udana zmiana hasła! Możesz się zalogować',
      check_your_email: 'Sprawdź swojego maila',
      to_reset_password: 'aby odzyskać hasło',
      current: 'Obecne hasło',
      new: 'Nowe hasło',
      repeat_new: 'Powtórz nowe hasło',
      change_success: 'Hasło zostało zmienione!',
    },
    lang_choose: {
      is: 'Wybór języka',
    },
    notification_preferences: {
      is: 'Preferencje powiadomień',
      email_notification: 'Powiadomienia e-mail',
      about_hearts: 'Otrzymuj powiadomienia o dostawanych serduszkach',
      about_pairs: 'Otrzymuj powiadomienia o nowych parach',
      about_payments: 'Otrzymuj powiadomienia o statusie płatności',
      about_messages: 'Otrzymuj powiadomienia o nowych wiadomościach',
      push_status_text: 'Status powiadomień na tym urządzeniu i tej przeglądrce',
      push_not_yet_given: 'jeszcze nie przyznane',
      push_given: 'przyznane',
      push_not_given: 'nie przyznane'
    }
  },
  web_push: {
    on: 'Włącz powiadomienia',
    off: 'Powiadomenia wyłączone',
    we_cant_turn_on: 'Nie możemy włączyć powiadomień',
    manual_turn_on_help: 'Aby włączyć powiadomienia muszisz przejść do ustawień przeglądarki i nadać uprawnienia powiadomień dla naszej strony samodzielnie.',
    turn_on_description: 'Włącz powiadomienia o nowych <br> <strong>serduszkach</strong>, <strong>parach</strong>, <strong>wiadomościach</strong>?',
    yes_turn_on: 'Tak, włącz'
  },
  chat: {
    search: 'wyszukaj...',
    no_new: 'Nie masz żadnych czatów',
    lets_talk: 'Porozmawiajmy!',
    choose_person: 'Wybierz osobę, z którą chciałbyś porozmawiać.',
    no_more_messages: 'Nie ma więcej wiadomości',
    load_more_messages: 'Załaduj więcej wiadomości',
    send: 'Wyślij',
    do_you_want_to_dislike: 'Chcesz odrzucić wiadomość?',
    do_you_want_to_dislike_text: 'Jeżeli odrzucisz wiadomość dalsza komunikacja z użytkownikiem będzie nie możliwa',
    yes_dislike: 'Tak, odrzuć',
    new_messages: 'nowe wiadomości',
    new_message: 'nowa wiadomość',
    read: 'Przeczytano'
  },
  register: {
    do: 'Zarejestruj się',
    on_jaty: 'Rejestracja na JaTy',
    description_text: ' Szansa na znalezienie idealnego partnera. <br> Szybka i bezpieczna rejestracja.',
    you_got_free_month: 'Otrzymałeś od nas <b>darmowy</b> miesiąc!',
    social_login_error: 'Nie można zalogować się przez Facebooka. Prawdopodobnie Twój profil nie udostępnia adresu email.',
    or_register: 'lub zarejestruj się',
    first_name: 'Imię / pseudonim',
    accept_policy: 'Akceptuję <a href="/polityka-prywatnosci" target="_blank" rel="nofollow">politykę prywatności</a> oraz <a href="/regulamin" target="_blank" rel="nofollow">regulamin</a>.'
  },
  contact: {
    do: 'Kontakt',
    with_us: 'Kontakt z JaTy',
    with_us_do: 'Skontaktuj się z nami.',
    topic: 'Temat',
    describe: 'Opisz swój problem',
    thanks: 'Dziękujemy za przesłanie formularza. Odpowiemy jak najszybciej!',
  },
  facebook: {
    login: 'Logowanie przez Facebook'
  },
  faq: {
    is: 'FAQ',
    how_heart_works: 'Jak działa "serduszko"?',
    how_heart_works_answer: 'W chwili kiedy Ty i druga osoba wyślecie sobie "serduszka" zostaniecie automatycznie połączeni w parę. W chwili kiedy naciśniesz "serduszko" za pierwszym razem, automatycznie okażesz zainteresowanie - co oznacza, że osoba otrzyma powiadomienie.',
    make_attractive: 'W jaki sposób zwiększyć swoją atrakcyjność na JaTy?',
    make_attractive_answer_first_part: 'Przede wszystkim pamiętaj o tym, żeby uzupełnić swój profil zdjęciami oraz wszystkimi informacjami zgodnie z dostępnymi formularzami. Pomoże Ci to upewnić się, że użytkownicy przeglądający Twój profil, będą dopasowani do Ciebie.',
    make_attractive_answer_second_part: 'Pamiętaj, że wybór zdjęć jest równie ważny. Powinny one być dobrej jakości, przedstawiające Ciebie i tylko Ciebie. Grafiki, obrazki, awatary generalnie nie są zalecane.',
    inappropriate_content: 'Ktoś zamieścił nieodpowiednie treści!',
    inappropriate_content_answer_first_part: 'Po naciśnięciu ikonki',
    inappropriate_content_answer_second_part: 'na profilu lub karcie użytkownika będziesz mógł go zgłosić, a nasi admini priorytetowo zajmują się takim użytkownikiem, po to aby usunąć nieodpowiednie treści.',
    user_blocking: 'Jak działa blokowanie użytkownika?',
    user_blocking_answer: 'Użytkownik nie będzie mógł do Ciebie napisać wiadomości, nie będzie Cię widział na portalu, ale Ty również nie będziesz widzieć go w wynikach wyszukiwania.',
    no_answer: 'Nie znalazłeś odpowiedzi?',
    no_answer_answer: 'Skontaktuj się z nami poprzez formularz zgłoszeniowy.',
  },
  welcome: {
    incentive: 'Uzupełnij jak najwięcej informacji w swoim profilu, aby pomóc nam znaleźć najlepsze propozycje osób dla Ciebie',
    sex: 'Twoja płeć',
    looking_for_women: 'Kobiet',
    looking_for_men: 'Mężczyzn',
    looking_for: 'Szukam',
    your_city: 'Twoje miasto',
    your_year: 'Twój rocznik',
    preferred_partner_age: 'Poszukuję partnerów w wieku',
    about_title: 'Napisz coś o sobie',
    about_text: `
                <p class="mb-6">Niech inne osoby lepiej Cię poznają. Napisz coś  o sobie w kilku słowach lub zdaniach.</p>
                <p>Możesz napisać:</p>
                <ul class="mb-6 dotted">
                    <li>jaką jesteś osobą,</li>
                    <li>czym się zajmujesz i jakie masz hobby,</li>
                    <li>jakiej osoby szukasz i jakie są Twoje zamiary,</li>
                    <li>brak Ci słów? Wstaw emoji 😉</li>
                </ul>`,
    about_me: 'O mnie',
    smile: 'Uśmiech!',
    images_text: `Prześlij swoje najlepsze zdjęcia, aby zrobić wrażenie na osobach odwiedzających Twój profil.`,
    use_face_image: 'Wybierz zdjęcie, na którym widać Twoją twarz,',
    max_size: 'użyj zdjęć w formacie jpg lub png do 8MB,',
    use_your_images: 'nie używaj cudzych zdjęć,',
    use_appropriate_images: 'unikaj przesyłania zdjęć obraźliwych, nagich.',
    images_drop: `
                <p class="is-hidden-desktop-only is-hidden-touch  ">
                    Naciśnij tutaj lub przeciągnij zdjęcia z komputera.
                </p>
                <p class="is-visible-desktop is-hidden-widescreen ">Naciśnij tutaj aby dodać zdjęcia.</p>
                <p class="m-0">
                    Możesz zmieniać kolejność zdjęć.
                </p>`,
    max_images: 'Maksymalna liczba zdjęć to 8',
    image_required: 'Dodanie zdjęcia jest obowiązkowe.',
    image_from_fb: 'Pobraliśmy Twoje zdjęcia z Facebook\'a',
    main_after_text: 'Zdjęcie główne',
    skip_this_step: 'Pomiń ten krok',
    you_can_add_images_later: 'Zdjęcia możesz dodać później z poziomu edycji profilu',
    confirm_skipping: 'Tak, przejdź dalej',
  },
  emoji_picker: {
    most_used: 'Naczęściej używane',
    people: 'Ludzie',
    nature: 'Natura',
    objects: 'Obiekty',
    places: 'Miejsca',
    symbols: 'Symbole',
  },
  help: {
    is: 'Pomoc',
    and_contact: 'Pomoc i kontakt',
    center: 'Centrum pomocy',
    faq: 'FAQ',
  },
  users: {
    currently_looking_at_distance: 'Aktualnie szukasz w odległości',
    from_city: 'miejscowości',
    search_parameters: 'Parametry wyszukiwania',
    age_you_are_looking: 'Szukasz osób  w wieku',
    change_age: 'Zmień wiek',
    add_images: 'Dodaj zdjęcie',
    add_images_description: 'Dodaj swoje zdjęcie po to aby pomóc innym w znalezieniu Cię i odblokuj propozycje partnerów.',
    go_to_image_adding: 'Przejdź do dodawania zdjęć',
    want_to_see_more: 'Chesz zobaczyć więcej osób?',
    go_change_your_preferences: 'Dostosuj swoje preferencje, aby zobaczyć więcej propozycji osób.',
    change_preferences: 'Zmień parametry',
    he_sent_you_a_heart: 'Wysłał Ci serduszko',
    she_sent_you_a_heart: 'Wysłała Ci serduszko',
    height: 'Wzrost',
    physique: 'Budowa ciała',
    children: 'Dzieci',
    eye_color: 'Kolor oczu',
    marital_status: 'Stan cywilny',
    work: 'Praca',
    education: 'Edukacja',
    confession: 'Wyznanie',
    attitude_to_marriage: 'Stosunek do małżeństwa',
    report: 'Zgłoś',
    block: 'Zablokuj',
    report_user: 'Zgłoś użytkownika',
    reason: 'Powód',
    ugly_profile: 'Obraźliwy profil - zdjęcia / opis',
    report_other: 'Inne',
    stealing_personal_data: 'Próba wymuszenia danych osobowych',
    stealing: 'Próba kradzieży / wymuszenia pieniędzy',
    hate_talk: 'Propagowanie nienawiści',
    fake: 'Podszywanie się (podejrzenie fake konta)',
    also_block_user: 'Dodatkowo zablokuj użytkownika',
    sure_to_black_and_unpair: 'Czy na pewno chcesz zablokować użytkownika i zerwać parę?',
    sure_to_black: 'Czy na pewno chcesz zablokować użytkownika?',
    block_user: 'Blokada użytkownika',
    yes_block: 'Tak, zablokuj',
    blocked: 'Użytkownik został zablokowany',
    thanks_for_report: 'Dziękujemy za zgłoszenie',
    write_more: 'napisz więcej...',
    looking_for: 'Szukam',
    looking_for_women: 'kobiet',
    looking_for_men: 'mężczyzn',
    aged: 'w&nbsp;wieku',
    click: 'Naciśnij',
    and_start_talking: 'i zacznij <br> rozmawiać na czacie!',
    visits: 'Odwiedziny',
    images: 'Zdjęcia',
    who_visited_you: 'Kto Cię odwiedził?',
    deleted: 'Wybrany użytkownik nie istnieje',
    one_time: 'raz',
    times: 'razy',
    no_new_visits: 'Nie masz nowych wyświetleń',
    users_not_found: 'Użytkownik nie został znaleziony',
    pair: {
      are_you_sure_to_break: 'Chcesz zerwać parę',
      are_you_sure_to_break_confirm: 'Czy na pewno chcesz to zrobić?',
      yes_break: 'Tak, zerwij'
    },
    heart: {
      cancel_q: 'Chcesz anulować serduszko?',
      cancel_confirm: 'Serduszko które wysłałeś wcześniej zostanie anulowane.',
      yes_cancel: 'Tak, anuluj serduszko',
    }
  },
  current_user: {
    edit_profile: 'Edytuj profil',
    edit_images: 'Edytuj zdjęcia',
    basic_info: 'Informacje podstawowe',
    edit_success: 'Edycja profilu udana!',
    more: {
      about_me: 'Więcej o mnie'
    },
    preferences: {
      is: 'Preferencje'
    },
    images: {
      is: 'Twoje zdjęcia',
      header: 'Zaprezentuj się z <span>najlepszej</span> strony!',
      from_computer: 'Naciśnij tutaj lub przeciągnij zdjęcia z komputera',
      from_mobile: 'Naciśnij tutaj aby dodać zdjęcia',
      can_change_order: 'Możesz zmieniać kolejność zdjęć.',
      uploaded_images: 'Przesłane zdjęcia',
      max_size_image: 'Zdjęcie {name} nie może być większe niż 8MB',
      order_changed: 'Edycja kolejności udana!',
      confirm_deleted: 'Usuwanie zdjęć',
      confirm_deleted_text: 'Czy na pewno chcesz to zrobić? Tej operacji nie można cofnąć?',
    },
    lang: {
      choose: 'Wybór języka'
    }
  },
  profile: {
    edit: 'Edycja profilu'
  },
  premium: {
    is: 'Premium',
    account: 'Konto premium',
    buy: 'Przedłużanie premium',
    see_with: 'Zobacz z',
    PREMIUM: 'PREMIUM',
    to: 'Twoje konto jest premium do',
    see_benefits: 'Zobacz korzyści premium',
    hide: 'Schowaj',
    benefits: 'Możliwości',
    free: 'Free',
    no_ads: 'Brak reklam',
    be_higher: 'Wyświetlaj się wyżej <span class="is-hidden-mobile"> innym użytkownikom</span>',
    ukraine: `🇺🇦 100% wpłat wykonanych przez naszych użytkowników w okresie 05.03.2022 do odwołania przekażemy na zbiórkę <a
      href="https://pomagam.pl/solidarnizukraina" target="_blank" rel="nofollow"> 🇺🇦 "Solidarni z Ukrainą"</a>
      organizowaną przez fundację <a href="https://pomagam.pl/" target="_blank" rel="nofollow">pomagam.pl</a>.
      Dokumentacja tej incjatywy dostępna będzie na naszym profilu na <a
      href="https://www.facebook.com/jatyportalrandkowy" target="_blank" rel="nofollow">facebook'u</a>. Zachęcamy do
      polubienia 🇺🇦`,
    see: 'Zobacz',
    more_with: 'Więcej korzyści z',
    expires: 'Jesteś premium do'
  },
  like: {
    hearts: 'Serduszka',
    sent: 'Wysłane',
    received: 'Otrzymane',
    you_didnt_sent_to_anyone: 'Jeszcze nikomu nie wysłałeś serduszka',
    go_to_propositions_to_like: 'Przejdź do propozycji i nawiąż kontakty z osobami, które pasują do Twoich preferencji.',
    linking_description: 'Tutaj widzisz osoby, którym wysłałeś serduszka w kolejności od najnowszych serduszek.',
    nobody_sent_you: 'Jeszcze nikt nie wysłał Ci serduszka'
  },
  pair: {
    yours: 'Twoje pary',
    page_description: 'Tutaj widzisz osoby, które Ci się podobają z wzajemnością',
    no: 'Nie masz jeszcze żadnej pary'
  },
  delete_request: {
    title: 'Usuwanie konta',
    description: `
    <p class="mb-2">Usunięcie konta wiąże się z usunięciem:</p>
    <ul class="mb-2 dotted">
      <li>twoich danych,</li>
      <li>twoich zdjęć,</li>
      <li>twojej historii wiadomości,</li>
      <li>twojego abonamentu.</li>
    </ul>
    <p class="mb-2">Nie informujemy mailowo procesie, a więc:</p>
    <ul class="dotted mb-2">
      <li><b>Nie</b> otrzymasz powiadomienia o usunięciu konta.</li>
      <li><b>Nie</b> otrzymasz powiadomienia o wysłaniu rządaniu.</li>
      <li><b>Nie</b> otrzymasz powiadomienia o dodatkowych pytaniach.</li>
    </ul>
    <p class="has-text-danger delete-text mb-2">Tego procesu nie można cofnąć.</p>
    <p class="mb-2">Będziesz mógł się zarejestrować ponownie.</p>
    <p class="mb-0">Jeżeli jesteś pewien tego, że chcesz usunąć swoje konto, wpisz powód i poproś u usunięcie konta.</p>`,
    reason: 'Co najbardziej nie podobało Ci na naszym portalu? (obowiązkowe)',
    ask: 'Poproś o usunięcie konta',
    cant_cancel: 'Pamiętaj, że tego procesu nie można cofnąć.',
    yes_delete: 'Tak, poproś o usunięcie konta',
    no: 'Jednak nie',
    last_step: 'Ostatni krok',
    press_yes_to_delete: 'Naciśnij "Tak" jeżeli chcesz trwale wykasować swoje dane',
    permanently: 'Tak, bezpowrotnie usuń konto'
  },
  email_confirmation: {
    resend: 'Wyślij jeszcze raz.',
    didnt_received: 'E-mail nie dotarł?',
    go_to_email: 'Przejdź na swój e-mail, aby go potwierdzić',
    mail_sent: 'Mail został wysłany',
    already_send_in_10_minutes: 'W ciagu ostatnich 10 minut poprosiłeś o ponowne wysłanie maila',
    on_mail: 'na skrzynkę',
    you_she_received: 'dostałaś',
    you_he_received: 'dostałeś',
    email_sent_from_us: 'od nas e‑mail z instrukcjami jak potwierdzić Twoje konto.',
    do_now: 'Potwierdź swój adres email!',
    great_last_step: 'Świetnie! Ostatni krok!'
  },
  payment: {
    you_save: 'Oszczędzasz',
    mon: 'mies',
    choose_one: 'Wybierz jedeną z opcji',
    go_to_p24: 'Przejdź do Przelewy24',
    thanks_title: 'Dziękujemy za opłacenia zamówienia!',
    thanks_text: 'Poinforujemy Cię kiedy tylko dostaniemy informację o Twojej płatności.',
    choosen: 'Wybrano',
    digital: {
      active: 'Nie muszisz już nic robić. Twoja subskrypcja jest aktywna.',
      deactived_globally: 'Płatności z poziomu aplikacji wyłączone.',
      manage: 'Zarządzaj subskrybcją',
      active_now: 'Aktywuj teraz',
      seven_days_free: '7 dni za darmo',
      with_subscription: 'subskrybcję',
    },
  },
  alert: {
    is: 'Powiadomienia',
    description: 'Widzisz listę powiadomień, które pozwalają Ci kontrolować co nowego dzieje się na Twoim koncie.',
    read_all: 'Oznacz wszystkie jako przeczytane',
    no_new: 'Nie masz nowych powiadomień',
    nothing: 'Nic ciekawego się nie dzieje',
    see_all: 'Zobacz wszystkie powiadomienia',
    one_more_step: 'Jeszcze jeden krok',
    new: 'Nowe',
    add_more_info_about_you: 'Uzupełnij informacje o sobie',
    you_are_premium: 'Jesteś premium',
    your_account_is_premium: 'Twoje konto zostało przedłużone',
    you_are_pair: 'Jesteście parą'
  },
  premium_box: {
    twa: `
     <span class="has-text-primary">Premium</span> na 7 dni za FREE! Potem&nbsp;<span
              class="has-text-danger">50&#37;</span>&nbsp;zniżki.`,
    left: 'Pozostało',
    countdown_text: `<span class="has-text-primary">Premium</span> za <span class="countdown-price">29.99&nbsp;zł</span>
              <span class="countdown-promo-price has-text-danger">69.99&nbsp;zł</span>
              na&nbsp;12&nbsp;miesięcy! <br>`,
    text: `JaTy staje się portalem <span class="has-text-primary">darmowym</span> dla wszystkich! Od piątego marca
           2022 premium to tylko dwie korzyści:&nbsp;"brak reklam" i "nieznaczne wyświetlanie się wyżej w wynikach
           wyszukiwania". Reszta funkcjonalności staje się darmowa! <br><br> 🇺🇦 100% wpłat wykonanych przez naszych
           użytkowników w okresie 05.03.2022 do odwołania przekażemy na zbiórkę
           <a href="https://pomagam.pl/solidarnizukraina" target="_blank" rel="nofollow">🇺🇦 "Solidarni z Ukrainą"</a>
           organizowaną przez fundację <a href="https://pomagam.pl/" target="_blank" rel="nofollow">pomagam.pl</a>.
           Dokumentacja tej incjatywy dostępna będzie na naszym profilu na <a
           href="https://www.facebook.com/jatyportalrandkowy" target="_blank" rel="nofollow">facebook'u</a>.
           Zachęcamy do  polubienia 🇺🇦`
  },
  city_chooser: {
    loading: 'Ładowanie',
    no_results: 'Brak wyników'
  },
  home: {
    h1: 'Darmowy portal randkowy',
    go_for_jaty: 'Przejdź na JaTy',
    login: 'Logowanie',
    register: 'Rejestracja',
    thousands_of_users: 'Tysiące użytkowników',
    every_day_new_users: 'Codziennie nowi użytkownicy!',
    totally_free: 'Całkowicie darmowy',
    all_features_free: 'Wszystkie funkcje dostępne za darmo!',
    only_real_accounts: 'Tylko prawdziwe konta',
    accounts_constantly_verified: 'Weryfikowane na bieżąco!',
    chat_graphic_section_h1: 'Portal randkowy - znajdź prawdziwą miłość',
    chat_graphic_section: `
          <p class="mb-3">
            Randki online JaTy to prawdziwa szansa na znalezienie prawdziej miłości.
            Czy wiesz, że już co druga para w Europie poznaje się przez internet? Ludzie szukają miłości i jej
            potrzebują. Nie ważne czy jesteś nieśmiałą osobą, czy też duszą towarzystwa, w internecie zawsze masz dużo
            większą szansę poznać osobę, która będzie Cię kochać, która będzie Ci towarzyszyć w każdej chwili w
            Twoim życiu.
          </p>
          <p>
            Nasza działalność skierowana jest do osób, które marzą o tym by poznać kogoś wspaniałego, wyjątkowego.
            Dzięki wszystkim niezbędnym funkcjom takim jak czat, serduszka, parowanie dostajesz wszystko co trzeba żeby zacząć poszukiwania.
          </p>`,
    free_heading: 'JaTy staje się portalem <strong class="has-text-primary">darmowym</strong>',
    free_heading_text: 'Czy wiesz, że premium to tylko dwie korzyści "brak reklam" i "nieco wyższe wyświetlanie się w wynikach wyszukiwania". Reszta funkcjonalności staje się darmowa i dostępna dla każdego użytkownika!',
    online_to_live_heading: 'Randkowanie online zmienia się w randki na żywo',
    online_to_live: 'JaTy to zarówna aplikacja oraz portal randkowy dzięki którym będziesz mógł budować długoterminowe relacje, ale również poznawać przyjaciół po to, aby pójść z kimś na drinka lub do kina. Skierowany jest do wszystkich osób samotnych, być może trochę nieśmiałych, które potrzebują pomocy w tym co w dzisiejszych czasach bywa szczególnie trudne - znalezienie miłości i przyjaźni.',
    we_will_help_you_for_free_heading: 'Tylko JaTy pomoże Ci znaleźć miłość za darmo!',
    we_will_help_you_for_free: 'Czy chciałbyś już dziś poznać swoją miłość? Czy czujesz się samotny? Czy jesteś singielką lub singlem, który chciałby kogoś poznać? Jeżeli na które kolwiek z tych pytań odpowiedziałeś twierdząco to znaczy znaczy, że portal randkowy i aplikacja randkowa JaTy jest dla Ciebie. Zobacz jak prawdziwa miłość może zmienić Twoje życie!',
    app_heading: 'Aplikacja randkowa JaTy',
    app: 'JaTy to portal randkowy dostępny również w aplikacji, która jest dostepna na system Android w sklepie Google Play. Zachęcamy do instlacji. Pamiętaj, że tym samym kontem możesz logować się zarówno w przeglądarce jak i w apce. Zabierz swoje randkowanie ze sobą, wraz z korzyściami płynącymi z używaniem aplikacji jak powiadomienia push bezpośrednio w Twoim telefonie, gdziekolwiek jesteś.',
    faq_title: 'JaTy Darmowe Randki Online - Najczęściej zadawane pytania (FAQ)?',
    faq: ` <h3 class="home-faq-title">Czy naprawdę portal randkowy JaTy jest darmowy?</h3>
            <p>
              TAK. Opcjonalne premium odpowiada tylko, za to, że wyświetlasz się nieco wyżej w wynikach wyszukiwania,
              oraz
              nie wyświetlanie reklam.
            </p>
            <p class="mt-1">Pozatym wszystkie dostępne funkcje portalu dostępne są za darmo.</p>
            <h3 class="home-faq-title mt-5">Dlaczego JaTy jest darmowy?</h3>
            <p>
              Odpowiedź na to pytanie dlaczego nasz portal dostępny jest za darmo jest bardzo prosta - chcemy dać wam
              szansę na znalezienie prawdziwej miłości. Dlatego nadal będziemy skoncetorwani na braku wulgarnych treści,
              oraz dostarczanie ludziom narzędzi potrzebnych do randkowania online.
            </p>

            <h3 class="home-faq-title mt-5">Kim będą ludzie których poznam?</h3>
            <p>
              JaTy zrzesza ludzi z całej Polski i ze świata, którzy szukają swojej miłości. Przywiązujemy ogromną wagę
              do
              tego, aby
              na naszym portalu nie było tzw. fake'ów czyli nieprawdziwych kont, zakładanych przez nieuczciwych
              użytkowników.

              Nie dajemy gwarancji, że nasi użytkownicy będą prawdziwi, jednak mamy najlepszy współcznynik prawdziwych
              kont,
              ze wszystkich portali randkowych.
            </p>

            <h3 class="home-faq-title mt-5">
              Czy rejestracja jest trudna?
            </h3>
            <p>
              Oczywiście, że nie
              <a href="{register_url}">rejestracja</a>
              JaTy randki online jest trywialnie prosta i zajmuje kilka sekund.
            </p>

            <h3 class="home-faq-title mt-5">Jak działają portale randkowe?</h3>
            <p>Różne rozwiązania widywane są na różnych portal, jednak generalny cel jest zawsze ten sam - znalezenie
              miłości.</p>

            <h3 class="home-faq-title mt-5">Czy JaTy jest dostępny tylko dla osób dorosłych?</h3>
            <p>Tak, ale nie ze względu na kontrowersyjne treści, zamieszczane przez użytkowników. JaTy nie jest portalem
              do
              szukania zarobku czy oszukiwania innych, a naszym celem i misją jest pomaganie ludziom w znalezeniu prawdziwej
              miłości </p>

            <h3 class="home-faq-title mt-5">W jaki sposób dbamy o Twoje dane</h3>
            <p>W jaki sposób dbamy o Twoje dane?
              Nikt niezarejestrowany na portalu lub w aplikacji nie zobaczy tam Ciebie, ani żadnych Twoich danych.
              Nigdy nie udostępniamy danych użytkowników innym podmiotom.
              Portal
              JaTy stosuje bardzo wysokie standardy bezpieczeństwa po to aby, nasi użytkownicy mogli pozostać anonimowi
              w
              poza portalem i aplikacją.</p>`,
    safe_guarantee_title: 'Gwarancja bezpieczeństwa',
    ssl: 'Certyfikat SSL',
    spam_filter: 'Filtr antyspamowy',
    fake_control: 'Kontrola fake kont'
  },
  meta: {
    home: 'JaTy to darmowy portal i aplikacja randkowa, dzięki którym znajdziesz swoją miłość. Prosta, szybka i darmowa rejestracja pozwoli Ci zacząć randkowanie już teraz! Miłość czeka na Ciebie nawet dzisiaj! ',
    login: 'Logowanie - JaTy randki online to portal i aplikacja randkowa, dzięki którym znajdziesz swoją miłość. Prosta, szybka i darmowa rejestracja pozwoli Ci zacząć randkowanie już teraz! Miłość czeka na Ciebie nawet dzisiaj!',
    contact: 'Kontakt - JaTy randki online to portal i aplikacja randkowa, dzięki którym znajdziesz swoją miłość. Prosta, szybka i darmowa rejestracja pozwoli Ci zacząć randkowanie już teraz! Miłość czeka na Ciebie nawet dzisiaj!',
    logout: 'Wyloguj się - JaTy randki online to portal i aplikacja randkowa, dzięki którym znajdziesz swoją miłość. Prosta, szybka i darmowa rejestracja pozwoli Ci zacząć randkowanie już teraz! Miłość czeka na Ciebie nawet dzisiaj!',
    register: 'Rejestracja - JaTy randki online to portal i aplikacja randkowa, dzięki którym znajdziesz swoją miłość. Prosta, szybka i darmowa rejestracja pozwoli Ci zacząć randkowanie już teraz! Miłość czeka na Ciebie nawet dzisiaj!',
    redirect: 'Znajdź miłość online - JaTy randki online to portal i aplikacja randkowa, dzięki którym znajdziesz swoją miłość. Prosta, szybka i darmowa rejestracja pozwoli Ci zacząć randkowanie już teraz! Miłość czeka na Ciebie nawet dzisiaj!',
    fast_login: 'Szybkie logowanie - JaTy randki online to portal i aplikacja randkowa, dzięki którym znajdziesz swoją miłość. Prosta, szybka i darmowa rejestracja pozwoli Ci zacząć randkowanie już teraz! Miłość czeka na Ciebie nawet dzisiaj!',
    login_layout: 'JaTy randki online to portal i aplikacja randkowa, dzięki którym znajdziesz swoją miłość. Prosta, szybka i darmowa rejestracja pozwoli Ci zacząć randkowanie już teraz! Miłość czeka na Ciebie nawet dzisiaj!',
    regulamin: 'Regulamin - JaTy randki online to portal i aplikacja randkowa, dzięki którym znajdziesz swoją miłość. Prosta, szybka i darmowa rejestracja pozwoli Ci zacząć randkowanie już teraz! Miłość czeka na Ciebie nawet dzisiaj!',
    polityka_prywatnosci: 'Polityka prywatności - JaTy randki online to portal i aplikacja randkowa, dzięki którym znajdziesz swoją miłość. Prosta, szybka i darmowa rejestracja pozwoli Ci zacząć randkowanie już teraz! Miłość czeka na Ciebie nawet dzisiaj! ',
    cookies: 'Polityka cookies - JaTy randki online to portal i aplikacja randkowa, dzięki którym znajdziesz swoją miłość. Prosta, szybka i darmowa rejestracja pozwoli Ci zacząć randkowanie już teraz! Miłość czeka na Ciebie nawet dzisiaj! '
  },
  title: {
    login_layout: 'JaTy - Darmowe randki online - Portal i aplikacja randkowa',
    help: 'Centrum pomocy - JaTy Randki online',
    home: 'JaTy - Darmowy portal randkowy - Portal randkowy',
    welcome: {
      index: 'Witaj - JaTy Randki online',
      about: 'Witaj - JaTy Randki online',
      images: 'Zdjęcia - JaTy Randki online'
    },
    users: {
      index: 'Propozycje - JaTy Randki online',
      liked: 'Serduszka - JaTy Randki online',
      liking: 'Serduszka - JaTy Randki online',
      _slug: ' - JaTy Randki online'
    },
    'current-user': {
      'verify-email': 'Potwierdź swój e-mail',
      payments: {
        digital: 'Premium - JaTy Randki online',
        index: 'Premium - JaTy Randki online',
        thanks: 'Dziękujemy - JaTy Randki online'
      },
      edit: {
        'delete-request': 'Usunięcie konta - JaTy Randki online',
        images: 'Zdjęcia - JaTy Randki online',
        index: 'Informacje podstawowe - JaTy Randki online',
        lang: 'Wybór języka - JaTy Randki online',
        'notification-preferences': 'Preferencje powiadomień - JaTy Randki online',
        password: 'Zmiana hasła - JaTy Randki online',
        preferences: 'Preferencje - JaTy Randki online',
        profile: 'Więcej o mnie - JaTy Randki online'
      }
    }
  },
  terms_of_use: `<h2>Ogólne Warunki Świadczenia Usługi Jaty (zwane dalej „Warunkami ogólnymi")</h2>
    <p>Ogólne Warunki Świadczenia Usługi Jaty określają zasady korzystania z serwisu Jaty (zwanego dalej „Serwisem Jaty"), obsługiwanego przez Detektyw Robert Zatorski ul. Wesoła 51/702 25-363 Kielce NIP: 735 126 50 58,  REGON: 260012935, zwaną dalej „Jaty".</p>
    <p>Z chwilą zarejestrowania w Serwisie Jaty Klient oświadcza i zapewnia, że przeczytał i zaakceptował Warunki ogólne. Warunki ogólne stanowią tym samym integralną część umowy.</p>
    <p>Warunki ogólne obowiązują wszystkich klientów, również tych, którzy korzystają z Serwisu Jaty poza terytorium Polski.</p>
    <p>Korzystać z Serwisu Jaty mogą osoby, które ukończyły 18 lat.</p>
    <p>1. Przedmiot umowy</p>
    <p>(1) Przedmiotem umowy zawartej pomiędzy Klientem a Serwisem Jaty mogą być zarówno świadczenia nieodpłatne, jak i odpłatne. Rejestracja w Serwisie Jaty jest bezpłatna, dodatkowo płatne usługi świadczone są tylko i wyłącznie na wyraźne zlecenie Klienta po uiszczeniu stosownej opłaty. Przed skorzystaniem ze świadczenia odpłatnego Klient informowany jest, że dane świadczenie jest odpłatne oraz jaki jest jego koszt. Serwis Jaty oferuje Klientowi między innymi następujące świadczenia (patrz również punkt 3. „Serwis Jaty oraz zakres świadczeń"):</p>
    <p>Wprowadzenie Klienta do bazy danych Serwisu Jaty oraz zarządzanie danymi;</p>
    <p>Umożliwienie kontaktu z innymi Klientami Serwisu Jaty .</p>
    <p>(2) Przedmiotem umowy pomiędzy Klientem a Serwisem Jaty nie jest ani kojarzenie małżeństw, ani pośrednictwo matrymonialne, ani aktywność zorientowana na bezpośrednie ustanowienie związku partnerskiego lub małżeństwa.</p>
    <p>(3) Serwis Jaty oferuje swoje usługi wyłącznie w celach prywatnych. Rejestrując się do Serwisu Jaty Klient zobowiązuje się wykorzystywać Serwis Jaty wyłącznie do celów prywatnych, a nie zarobkowych lub zawodowych.</p>
    <p>(4) Do korzystania z Serwisu Jaty niezbędne jest posiadanie urządzeń pozwalających na korzystanie z Internetu, skrzynki pocztowej e-mail oraz przeglądarki zasobów internetowych umożliwiającej wyświetlanie stron WWW.</p>
    <p>2. Korzystanie z Serwisu Jaty oraz zawarcie umowy</p>
    <p>(1) Korzystanie z Serwisu Jaty wymaga rejestracji. Podczas rejestracji Klient wybiera nazwę użytkownika, pod którą widoczny będzie dla innych klientów Serwisu Jaty, oraz hasło osobiste. Ewentualnie Serwis Jaty może przydzielić Klientowi w miejsce wybieranej samodzielnie nazwy użytkownika tzw. „kod". Podany adres e-mail służy wyłącznie kontaktowaniu się Serwisu Jaty z Klientem i nie jest przekazywany innym Klientom Serwisu Jaty.</p>
    <p>(2) Rejestrując się Klient akceptuje niniejsze Warunki ogólne oraz oświadcza, że jest pełnoletni, tzn. że ukończył 18 lat.</p>
    <p>(3) Umowa pomiędzy Klientem a Serwisem Jaty zostaje zawarta z chwilą zarejestrowania się Klienta. Umowy tej dotyczą niniejsze Ogólne Warunki. Rejestracja jest bezpłatna.</p>
    <p>(4) W momencie zamówienia odpłatnego świadczenia zarejestrowany Klient uzyskuje tzw. „konto Premium", do którego także zastosowanie mają Warunki ogólne. Klient informowany jest o odpłatności świadczenia oraz o obowiązujących cenach i warunkach zapłaty. Umowa dotycząca odpłatnych świadczeń zostaje zawarta z chwilą zaakceptowania przez Klienta obowiązku zapłaty na stronie internetowej Serwisu Jaty poprzez kliknięcie pola akceptacji.</p>
    <p>(5) Klient oświadcza, że dane podane podczas rejestracji, zamówienia oraz zapłaty są prawdziwe.</p>
    <p>(6) Jaty ma prawo zablokować dostęp do serwisu, jeżeli klient   korzysta z serwisu i/lub strony internetowej naruszając przepisy prawne; naruszając jedno lub więcej praw osób trzecich lub też łamie obowiązujące obecnie obowiązki klienta - szczegóły patrz dalej. 6. Obowiązki Klienta. Blokada dostępu lub usunięcie konta ze wskazanych powyżej przyczyn nie zwalnia Klienta z obowiązku zapłaty za zamówione świadczenie. W przypadku ciężkiego naruszenia przez Klienta postanowień Warunków ogólnych lub umowy, Serwis Jaty zastrzega sobie prawo do naliczenia kary umownej w wysokości do (350,00 EUR). Żądanie przez Serwis Jaty odszkodowania przenoszącego wysokość zastrzeżonej kary umownej jest dopuszczalne.</p>
    <p>3. Serwis Jaty oraz zakres świadczeń</p>
    <p>(1) Świadczenie usług przez Serwis Jaty rozpoczyna się z chwilą rejestracji oraz wypełnieniem danych profilu. Wypełnianie składa się z szeregu pytań, na które Klient udziela odpowiedzi.</p>
    <p>(2) Odpowiedzi Klienta oraz dalsze dane osobowe wprowadzane są do bazy danych Serwisu Jaty i tam są porównywane z danymi innych Klientów Serwisu Jaty. Porównanie następuje na podstawie algorytmu matematycznego (operacji obliczeniowej), umożliwiającego określenie zgodności dwóch profili Klientów.</p>
    <p>(3) Jako wynik porównania danych Klienta z danymi innych Klientów Serwisu Jaty, Klient otrzymuje listę tych Klientów, którzy najbardziej do niego pasują, w formie anonimowych profili skróconych.</p>
    <p>(4) Klient może wówczas skontaktować się z zaproponowanymi mu innymi Klientami Serwisu Jaty. Bezpośredni kontakt pomiędzy Klientami Serwisu Jaty możliwy jest dopiero w przypadku wzajemnej zgody Klientów. Taka wzajemna zgoda może zostać uzyskana w ten sposób, że dany Klient prześle swój anonimowy profil skrócony do wybranych przez siebie Klientów (tzw. „nawiązanie kontaktu"). Drugi Klient może wówczas zadecydować, czy chce odpowiedzieć czy nie. Nawiązanie kontaktu w rozumieniu Warunków Ogólnych następuje również wówczas, gdy Klient przystaje na propozycje kontaktu ze strony innego Klienta. Komunikacja pomiędzy Klientami następuje za pośrednictwem wewnętrznego systemu komunikacyjnego Serwisu Jaty.</p>
    <p>(5) Możliwość nawiązywania kontaktów oraz przekazywania wiadomości jest bezpłatna, o ile podczas zawierania umowy nie uzgodniono inaczej.</p>
    <p>(6) Przez miesiąc należy rozumieć nie miesiąc kalendarzowy, tylko okres pełnych 31 dni.</p>
    <p>(7) Przed uzyskaniem dostępu do świadczeń odpłatnych Klient wyraźnie informowany jest o istniejącym obowiązku zapłaty. Przypadające koszty, zakres świadczeń oraz obowiązujące warunki zapłaty wyjaśniane są w ramach procedury zamawiania danego odpłatnego świadczenia.</p>
    <p>(8) Serwis Jaty nie gwarantuje, że podane przez Klientów dane są prawdziwe.</p>
    <p>Serwis Jaty nie gwarantuje, że w trakcie obowiązywania umowy dojdzie do wzajemnie skutecznego nawiązania kontaktu.</p>
    <p>(9) Zasadniczo, usługa świadczona przez Jaty dostępna jest 24/7. Dostępność szacuje się na 95% w ujęciu rocznym. Powyższa wartość nie obejmuje przerw związanych z konserwacją lub aktualizacją oprogramowania lub spowodowanych czynnikami, które nie są zależne od Jaty, jak niedostępność usług internetowych z winy osób trzecich lub z powodu siły wyższej. Jaty zaleca swoim klientom korzystanie z najnowszej (przeglądarki) technologii oraz zastosowanie odpowiednich ustawień przeglądarki (aktywacja Java script, cookies oraz pop-ups) dla lepszego użytkowania Jaty. W przypadku stosowania nieaktualnej lub niestandardowej technologii, Jaty nie może zagwarantować prawidłowego działania wszystkich usług i funkcji.</p>
    <p>(10) Klient przyjmuje do wiadomości i zgadza się, że wszystkie treści przesłane przez niego do serwisu Jaty są dostępne do wglądu przez wszystkich klientów z jego listy polecanych kontaktów. Ma to zastosowanie niezależnie od tego, czy inni klienci zarejestrowali się w serwisie prowadzonym przez Jaty czy za pośrednictwem innych stron internetowych/aplikacji czy partnerów współpracujących z Jaty. W ten sposób klienci mogą czerpać korzyści z centralnej bazy prowadzonej przez Jaty w stosunku do usług, które są dostępne za pośrednictwem różnych domen i serwisów internetowych/aplikacji , widząc tych użytkowników w kontaktach rekomendowanych.</p>
    <p>4. Wzajemne powiadomienia dokonywane przez Klienta i Serwis Jaty</p>
    <p>(1) Wiadomości z Jaty do klienta są wysyłane za pośrednictwem ekranów wyświetlanych po „Zalogowaniu”, tj. Kiedy klient wprowadził swoją nazwę użytkownika (lub przydzielony mu Kod) i hasło, lub na adres e-mail podany przez klienta podczas rejestracji lub aktualnie przechowywany przez klienta w jego profilu.</p>
    <p>(2) Wiadomości od Klienta do Serwisu Jaty przesyłane są pocztą elektroniczną na adres e-mail podany na stronie Jaty (np. kontakt@Jaty.pl), listem lub faksem. W przypadku kontaktu z Jaty listownie lub faksem, w celu unikalnej autoryzacji i ochrony przed nadużyciem, absolutnie konieczne jest, aby Klient podał przynajmniej swój adres e-mail zarejestrowany w serwisie Jaty, kraj, w którym korzysta z usług Jaty oraz nazwę użytkownika (lub pzrydzielony mu kod) w korespondencji z Jaty. W przypadku kontaktowania się z Jaty za pomocą poczty elektronicznej, w celu autoryzacji i ochrony przed nadużyciem, absolutnie konieczne jest, aby wszystkie wiadomości e-mail wysyłane przez Klienta do Jaty były wysyłane z adresu e-mail podanego przez Klienta podczas rejestracji lub obecnie przechowywanego przez Klienta w jego profilu.</p>
    <p>5. Odpowiedzialność cywilna Serwisu Jaty</p>
    <p>(1) Serwis Jaty nie odpowiada w żaden sposób w granicach zgodnych z obowiązującymi przepisami prawa za materiały i dane przekazywane i publikowane na łamach Serwisu Jaty przez Klientów oraz za ich prawdziwość, rzetelność i autentyczność. Serwis Jaty nie odpowiada za poprawność danych podanych przez Klientów Serwisu Jaty w teście osobowości. Serwis Jaty nie odpowiada tym samym również za prawidłowość sporządzonego wyniku testu oraz porównania profili Klientów, gdyż sporządzenie wyniku testu oraz porównanie profili Klientów następuje w oparciu o podane dane. Jeżeli dowiemy się o tym, że klient narusza przepisy prawne; narusza jedno lub więcej praw osób trzecich lub też narusza obowiązujące obecnie zobowiązania klienta określone w punkcie 6, wówczas zastosowanie znajduje punkt 2.6.</p>
    <p>(2) Bezpośrednie podjęcie kontaktu pomiędzy Klientami Serwisu Jaty następuje wyłącznie za obopólną zgodą (patrz punkt 3 „Serwis Jaty oraz zakres świadczeń"). Serwis Jaty nie ponosi tym samym odpowiedzialności, jeśli w okresie obowiązywania umowy nie zostanie podjęty żaden kontakt. Serwis Jaty dokłada natomiast wszelkich starań w zakresie pośrednictwa w kontaktach pomiędzy Klientami Serwisu Jaty poprzez zapewnienie odpowiednich warunków technicznych. Serwis Jaty nie odpowiada za zdarzenia, które mogą mieć lub miały miejsce w świecie rzeczywistym pomiędzy Klientami, którzy poznali się za pośrednictwem Serwisu Jaty.</p>
    <p>(3) Serwis Jaty nie odpowiada za nadużywanie przez innych danych i informacji, gdyż mimo wyraźnego zakazu (patrz punkt 6 „Obowiązki Klienta") Klienci mogą korzystać z Serwisu Jaty z naruszeniem prawa. Serwis Jaty nie odpowiada tym samym również w przypadkach, gdy informacje, które Klient sam udostępnił osobie trzeciej, wykorzystywane są przez osobę trzecią w sposób bezprawny.</p>
    <p>(4) Jaty nie gwarantuje, że serwis będzie działał bez zarzutu przez cały czas, tzn. że będzie nieustannie dostępny bez żadnych zakłóceń. W szczególności Jaty nie odpowiada za usterki sprzętu technicznego ani za jakość dostępu do usług z powodu działania siły wyższej lub zdarzeń będących poza kontrolą Jaty (np. awarii sieci komunikacyjnych).</p>
    <p>(5) Jaty nie odpowiada za bezprawne pozyskanie danych osobowych  klienta przez osoby trzecie, np. w formie dostępu do bazy danych przez hakerów.</p>
    <p>(6) Za straty inne niż wynikające z w/w przyczyn Jaty będzie odpowiadać tylko w przypadkach zamierzonego lub rażącego zaniedbania ze strony swego kierownictwa, pracowników lub wykonawców, a odpowiedzialność taka jest proporcjonalna do innych powodów, które przyczyniły się do powstania straty.</p>
    <p>(7) Jaty odpowiada tylko za nieznaczne zaniedbanie w przypadku uszczerbku na życiu, ciele lub zdrowiu lub w przypadku, gdy naruszył istotne zobowiązania umowne. Odpowiedzialność w tym drugim przypadku jest ograniczona do przewidywalnych, bezpośrednich strat typowych dla tego rodzaju stosunku umownego.</p>
    <p>(8) Odpowiedzialność kontraktowa jest poza tym wykluczona.</p>
    <p>6. Obowiązki Klienta</p>
    <p>(1) Klient ponosi wyłączną odpowiedzialność za wszelkie podane przez siebie dane. Zapewnia on, że podane przez niego dane są prawdziwe. Klient zobowiązuje się nie przekazywać danych osób trzecich jako własnych. Podawanie fałszywych danych narusza postanowienia umowy oraz Warunków Ogólnych i może wiązać się z podjęciem przez Serwis Jaty stosownych kroków prawnych.</p>
    <p>Klient zobowiązuje się do utrzymywania danych podanych w Serwisie Jaty, w szczególności danych kontaktowych oraz danych płatniczych, w stanie zaktualizowanym.</p>
    <p>Serwis Jaty uprawniony jest do sprawdzania treści profilu, w tym zamieszczanych zdjęć, pod względem ich zgodności z prawem, Warunkami ogólnymi oraz umową. Serwis Jaty uprawniony jest do odrzucania, zmiany, a nawet usuwania treści danych.</p>
    <p>(2) Klient nie powinien podawać informacji, które nie są od niego wyraźnie wymagane. Dotyczy to w szczególności nazwiska, adresu zamieszkania, adresu e-mail, adresu strony internetowej, numeru telefonu, faksu.</p>
    <p>(3) Klient zobowiązany jest do poufnego traktowania danych (w szczególności nazwisk, adresów zamieszkania, adresów e-mail, adresów stron internetowych, numerów telefonów oraz faksów) dotyczących poczty elektronicznej, wiadomości i powiadomień otrzymywanych od innych Klientów Serwisu Jaty oraz do nieudostępniania tych danych osobom trzecim bez zgody osoby, której one dotyczą. Przekazywanie danych osób niebędących Klientami Serwisu Jaty jest zabronione.</p>
    <p>(4) Klient zapewnia, że nie będzie prowadził działalności komercyjnej za pośrednictwem Serwisu Jaty oraz zobowiązuje się do nieprzesyłania innym Klientom Serwisu Jaty wiadomości w celach innych niż osobista komunikacja. W szczególności Klient nie będzie reklamować ani oferować towarów lub usług. Klient zobowiązuje się ponadto do nierozsyłania tzw. „spamów" itp. Obejmuje to w szczególności korzystanie z funkcji czatu wideo, która jest dostępna w niektórych krajach w ramach Konta Premium.</p>
    <p>(5) Klient zobowiązuje się, że nie będzie korzystać z Serwisu Jaty w celu:</p>
    <p>rozsyłania materiałów szkalujących, gorszących, pornograficznych lub sprzecznych z prawem;</p>
    <p>grożenia, nękania, zniesławiania itp.;</p>
    <p>obiecywania, lub żądania świadczeń pieniężnych;</p>
    <p>naruszania praw osób trzecich.</p>
    <p>Klient zobowiązuje się ponadto do:</p>
    <p>niewprowadzania do Serwisu Jaty danych zawierających wirusy (zainfekowanego oprogramowania);</p>
    <p>niewprowadzania do Serwisu Jaty danych zawierających oprogramowanie lub inne materiały chronione prawem autorskim lub prawem własności przemysłowej, chyba że Klient jest posiadaczem danego prawa lub posiada konieczną zgodę posiadacza prawa do korzystania z oprogramowania lub materiałów;</p>
    <p>niekorzystania z Serwisu Jaty w sposób mogący negatywnie wpływać na dostępność oferty Serwisu Jaty dla innych Klientów;</p>
    <p>nieingerowania w system komputerowy innych Klientów, w tym w szczególności do niepodejmowania bezprawnych działań związanych z pocztą elektroniczną innych Klientów.</p>
    <p>(6) Naruszenie przez Klienta jednego ze wskazanych powyżej obowiązków może prowadzić do wypowiedzenia stosunku umownego przez Serwis Jaty, co związane jest z natychmiastowym zablokowaniem dostępu do Serwisu Jaty. Wszelkie roszczenia ze strony Serwisu Jaty względem Klienta pozostają aktualne (patrz punkt 2 „Korzystanie z Serwisu Jaty oraz zawarcie umowy"(6)).</p>
    <p>(7) Jeżeli Klient wykorzysta dane znajdujące się w Serwisie Jaty do celów komercyjnych, Serwis Jaty może żądać zapłaty kary umownej w wysokości (2.500,- EUR) za każde pojedyncze naruszenie. Żądanie przez Serwis Jaty odszkodowania przenoszącego wysokość zastrzeżonej kary umownej jest dopuszczalne.</p>
    <p>(8) Klient zobowiązuje się zwolnić Serwis Jaty z wszelkich skutków powództw, roszczeń o odszkodowanie, strat i należności, które powstaną w związku z jego zarejestrowaniem się w Serwisie Jaty i korzystaniem z niego. Klient zwalnia Serwis Jaty w z wszelkiej odpowiedzialności cywilnej oraz z wszelkich zobowiązań i szkód, które mogą wyniknąć na skutek roszczeń powstałych z powodu pomówienia lub znieważenia, oraz naruszenia praw innych Klientów.</p>
    <p>(9) Klient winien odbierać napływające do niego wiadomości i odpowiadać na nie regularnie i w razie potrzeby archiwizować je na własnym komputerze lub innym nośniku danych.</p>
    <p>Serwis Jaty uprawniony jest, bez zgody i zawiadomienia Klienta do usuwania zapisanych na koncie Klienta wiadomości po upływie dwunastu (12) miesięcy od wysłania lub odebrania wiadomości przez Klienta.</p>
    <p>Serwis Jaty uprawniony jest, bez zgody i powiadomienia Klienta, do usunięcia konta Klienta, który nie korzysta z bezpłatnego Serwisu Jaty przez okres przynajmniej sześciu (6) miesięcy. Działanie to służy po pierwsze ochronie danych Klienta, gdyż Serwis Jaty przyjmuje, że po upływie tego terminu Klient nie jest już zainteresowany przechowywaniem jego danych, po drugie działanie to służy oczyszczeniu bazy danych Serwisu Jaty z nieaktywnych Klientów, co równoznaczne jest z poprawą jakości oferowanej usługi.</p>
    <p>7. Informacje dotyczące ochrony danych, przetwarzania danych oraz cookies</p>
    <p>            Nasza ochrona danych osobowych możesz znaleźć tutaj:  <a href="/polityka-prywatnosci">https://www.jaty.pl/polityka-prywatnosci</a></p>
    <p>8. Wypowiedzenie umowy, przedłużenie umowy oraz wstrzymanie świadczenia usługi</p>
    <p>(1) Wypowiedzenie umowy</p>
    <p>Klient może zawrzeć z Serwisem Jaty umowę o charakterze odpłatnym (umowa odpłatna) oraz umowę o charakterze nieodpłatnym (umowa nieodpłatna). Umowa nieodpłatna powstaje z chwilą rejestracji Klienta w Serwisie Jaty.</p>
    <p>Klient jest w każdej chwili uprawniony do wypowiedzenia umowy nieodpłatnej i odpłatnej bez podania przyczyny. Wypowiedzenie członkostwa następuje poprzez wypełnienie formularza kontaktowego w menu „Pomocy" na stronie internetowej Serwisu Jaty.</p>
    <p>Serwis Jaty uprawniony jest do wypowiedzenia nieodpłatnej umowy z terminem wypowiedzenia 14 dni. Niezależnie od powyższego Serwis Jaty jest w każdej chwili uprawniony do wypowiedzenia umowy w przypadkach wskazanych w niniejszych Warunkach ogólnych oraz umowie.</p>
    <p>(2) Przedłużenie umowy</p>
    <p>Umowa o wykonywanie odpłatnej usługi przedłużana jest automatycznie o okres uzgodniony w zamówieniu (np. 3, 6 względnie maksymalnie 12 miesięcy), o ile Klient nie wypowie umowy pisemnie co najmniej na 14 dni kalendarzowych przed upływem danego okresu umownego - chyba, że uzgodniony został inny termin - podając imię i nazwisko, kraj, w którym korzysta on z Serwisu Jaty, zarejestrowany w Serwisie Jaty adres e-mail oraz nazwę użytkownika (ewentualnie przydzielony mu kod). Patrz ustęp 1 niniejszego punktu „Wypowiedzenie umowy", akapit „Wypowiedzenie odpłatnej umowy".</p>
    <p>W przypadku abonamentu próbnego, akcji reklamowych itp. mogą zachodzić odstępstwa od tej reguły. W każdym przypadku Klient informowany jest o okresie trwania abonamentu próbnego, możliwości jego wypowiedzenia oraz jego ewentualnym automatycznym przedłużeniu.</p>
    <p>Przy zamawianiu odpłatnego świadczenia Klient informowany jest w momencie otrzymania potwierdzenia zamówienia o okresie trwania umowy, dacie rozpoczęcia obowiązywania umowy.</p>
    <p>Jaty poinformuje Użytkownika poprzez wiadomość email, przesłaną na adres email podany podczas rejestracji o zbliżającym się przedłużeniu abonamentu, przed jego przedłużeniem.</p>
    <p>(3) Zaprzestanie usług</p>
    <p>Serwis Jaty ma prawo zaprzestać częściowo lub całkowicie świadczenia oferowanych usług. Jaty poinformuje klienta o planowanym ustaniu usługi i jego zakresie z wyprzedzeniem co najmniej 14 dni przed  częściowym lub całkowitym ustaniem usługi.</p>
    <p>Klienci, którzy zapłacili, ale nie wykorzystali jeszcze w pełni swoich uprawnień do korzystania z serwisu Jaty w chwili zatrzymania usług, otrzymają proporcjonalny zwrot należności za niewykorzystane uprawnienia.</p>
    <p>9. Reklamacja, opóźnienie w zapłacie</p>
    <p>(1) Wszelkie reklamacje Klient może zgłaszać za pośrednictwem poczty elektronicznej lub pisemnie na podane poniżej adresy:</p>
    <p>Jaty Serwis Klienta ul. Orkana 8a 25-548 Kielce</p>
    <p>lub E-mail: Jaty@Jaty.pl</p>
    <p>(2) Zarzuty względem wysokości pobranych z konta lub wskazanych w fakturze kosztów Klient winien zgłosić do Serwisu Jaty i uzasadnić (na przykład błąd obliczeniowy) w formie pisemnej w terminie sześciu (6) tygodni od pobrania z konta kwestionowanych kosztów lub otrzymania faktury.</p>
    <p>W przypadku uprawnionego zarzutu dochodzona suma jest Klientowi zwracana lub oferowane jest przedłużenie wykonywania odpłatnego świadczenia („członkostwa Premium"). Przedłużenie wykonywania świadczenia odpowiada proporcjonalnie okresowi, do którego Klient wniósł uprawniony zarzut.</p>
    <p>Klient ma ponadto prawo wyznaczyć Serwisowi Jaty termin pięciu dni roboczych na przywrócenie prawidłowego wykonywania świadczenia. Jeśli przyczyna reklamacji nie zostanie usunięta po upływie tego terminu, Klient ma prawo do wypowiedzenia umowy. Zwrot niewykorzystanego salda następuje zgodnie z punktem 8 „Wypowiedzenie umowy, przedłużenie umowy oraz wstrzymanie świadczenia usługi", ustęp 1.</p>
    <p>Nieistotne utrudnienia w korzystaniu z Serwisu nie uprawniają do wnoszenia reklamacji. Nieistotne utrudnienie w korzystaniu z odpłatnego świadczenia zachodzi wówczas, gdy okres, w którym Klient nie może korzystać z odpłatnego świadczenia, nie przekracza dwóch następujących po sobie dni.</p>
    <p>Serwis Jaty zastrzega sobie prawo egzekwowania od Klienta w ramach ustawowych możliwości kosztów powstałych na skutek wykonania płatności za pośrednictwem karty kredytowej z naruszeniem przepisów prawa.</p>
    <p>10. Prawa autorskie</p>
    <p>(1) Wszelkie prawa do należących do Serwisu Jaty programów, procedur, treści oprogramowania, technologii, marek, nazw handlowych, wynalazków, materiałów oraz wszelkich innych praw własności intelektualnej będących częścią Serwisu Jaty przysługują wyłącznie Serwisowi Jaty. Korzystanie z wszelkich programów i zawartych w nich treści, materiałów oraz marek i nazw handlowych dopuszczalne jest wyłącznie do celów wskazanych w niniejszych Warunkach ogólnych. Powielanie należących do Serwisu Jaty programów, świadczeń, procedur, oprogramowania, technologii, marek, nazw handlowych, wynalazków oraz materiałów jest niedopuszczalne, o ile nie jest wyraźnie dozwolone przez Serwis Jaty.</p>
    <p>11. Prawo do odstąpienia od umowy, Informacja na temat wykonania prawa do odstąpienia od umowy</p>
    <p>Informacja na temat wykonania prawa do odstąpienia od umowy</p>
    <p>Prawo do odstąpienia od umowy</p>
    <p>Klient ma prawo do odstąpienia od umowy bez podawania przyczyny w ciągu czternastu dni.</p>
    <p>Okres odstąpienia od umowy wynosi czternaście dni od dnia zawarcia umowy.</p>
    <p>W celu dokonania odstąpienia od umowy, należy przesłać na adres JaTy, Obsługa klienta/Odstąpienie, ul.Orkana 8a 25-48 Kielce (E-mail: Jaty@jat.pl) wyraźne oświadczenie (np. listem wysłanym pocztą, lub e-mailem) o decyzji o odstąpieniu od umowy. W celu zachowania terminu do odstąpienia od umowy wystarczy poinformowanie o wykonaniu prawa do odstąpienia przed upływem tego terminu.</p>
    <p>Skutki odstąpienia</p>
    <p>W przypadku odstąpienia od umowy zwrócimy wszystkie otrzymane płatności, w tym koszty dostawy (z wyjątkiem kosztów dodatkowych, wynikających z wyboru innego sposobu dostawy niż oferowana przez nas, najtańsza dostawa standardowa), niezwłocznie i najpóźniej w ciągu czternastu dni od dnia otrzymania przez nas informacji o Państwa odstąpieniu od umowy. Do zwrotu płatności wykorzystany zostanie ten sam sposób płatności, z wykorzystaniem którego dokonano pierwotnej transakcji, o ile wyraźnie nie uzgodniono innego sposobu. Jednak w żadnym przypadku w związku ze zwrotem płatności nie zostaną pobrane opłaty.</p>
    <p>Jeśli zostaliśmy zobowiązani do rozpoczęcia świadczenia usług w okresie do odstąpienia od umowy, wówczas zobowiązują się Państwo do uiszczenia opłaty za usługi wykonane do momentu poinformowania o wykonaniu prawa do odstąpienia od umowy, proporcjonalnie od całego zakresu przewidzianego w umowie za świadczenie usług.</p>
    <p>12. Zmiana Warunków ogólnych</p>
    <p>(1) Warunki Ogólne zawarte w Regulaminie mogą ulegać zmianom w przypadku, gdy nie mają one wpływu na zasadnicze postanowienia dotyczące stosunku umownego i że taka zmiana jest konieczna w celu adaptacji kwestii nieprzewidzianych w chwili zawierania umowy, a które w znaczący sposób naruszyłyby równowagę stosunku umownego, gdyby pozostały nieuwzględnione. "Zasadnicze postanowienia" to w szczególności takie, które odnoszą się do typu i zakresu uzgodnionych umownie usług i ich czasu trwania, włącznie z postanowieniami regulującymi ich zakończenie. Dalsze modyfikacje lub uzupełnienia Regulaminu mogą mieć miejsce, jeżeli jest to konieczne w celu rozwiązania trudności związanych z realizacją umowy, spowodowanych przez luki wynikłe po zawarciu umowy. W szczególności może to mieć miejsce, gdy zmianom ulega prawodawstwo i gdy ma to wpływ na jeden lub więcej punktów Regulaminu .</p>
    <p>(2) Specyfikacje dotyczące usług mogą ulec zmianie, jeżeli istnieje uzasadniona potrzeba, pod warunkiem, że zmiany te nie spowodują obiektywnie mniej korzystnej sytuacji klienta (np. konieczność serwisowania lub ulepszenia funkcjonalności serwisu) niż powodowały ją specyfikacje usług obowiązujące w chwili zawierania umowy i nie różnią się one w zasadniczy sposób od wcześniejszych specyfikacji. Uzasadniona potrzeba dokonania zmian zachodzi wówczas, gdy na rynku dostępne są innowacje techniczne lub, gdy osoby trzecie, od których Jaty uzyskuje zaawansowane usługi niezbędne do prawidłowego funkcjonowania serwisu,  zmieniają zakres swoich świadczeń.</p>
    <p>(3)   Klient musi zostać poinformowany o zmianach w Regulaminie oraz w specyfikacjach usług zgodnie z paragrafem 12. (1) i (2) co najmniej sześć (6) tygodni przed ich wejściem w życie. W chwili ich wejścia w życie klient powinien mieć specjalne prawo do zakończenia umowy. Jeżeli klient nie zakończy umowy na piśmie w ciągu sześciu (6) tygodni od otrzymania powiadomienia o zmianach, zmiany te staną się częścią umowy w chwili ich wejścia w życie. Klient zostanie wyraźnie powiadomiony o takim skutku poprzez powiadomienie o zmianach.</p>
    <p>13. Pozostałe</p>
    <p>(1) Jaty ma prawo zlecić osobom trzecim, które są jego usługodawcami i wykonawcami, świadczenie części lub całości zakresu świadczonych usług, pod warunkiem że nie powoduje to jakichkolwiek niekorzyści dla klienta.</p>
    <p>(2) Jeśli poszczególne postanowienia niniejszych Warunków ogólnych bądź umowy są lub staną się nieważne ważność pozostałych postanowień pozostaje wiążąca dla stron.</p>
    <p>(3) Obowiązującym prawem w stosunkach pomiędzy Klientem i Serwisem Jaty jest prawo polskie, z zastrzeżeniem obowiązkowych postanowień Rozporządzenia UE nr 593/2008.</p>
    <p>(4) Jaty nie uczestniczy w unijnym internetowym systemie rozstrzygania sporów (ODR) i nie ma do tego obowiązku: https://ec.europa.eu/consumers/odr/</p>
    <p>(5) Właścicielem Serwisu Jaty jest spółka Detektyw Robert <Zatorski></Zatorski> ul. Wesoła 51 pok. 702, (VII-piętro)25-363 Kielce NIP: 735 126 50 58</p>`,

  privacy_policy_content: `<h2>  Polityka prywatności serwisu www.jaty.pl</h2>
    <p>Informacje ogólne</p>
    <p>
    1. Niniejsza Polityka Prywatności określa zasady przetwarzania i ochrony danych osobowych przekazanych przez Użytkowników w związku z korzystaniem przez nich usług z portalu randkowe poprzez Serwis.
    </p>
    <p>
      2. Administratorem danych osobowych zawartych w serwisie jest
    Allset Piotr Zatorski z siedzibą w Kielcach ul. Orkana 8a 25-548 NIP: 9591864486
    </p>
    <p>

    3. W trosce o bezpieczeństwo powierzonych nam danych opracowaliśmy wewnętrzne procedury i zalecenia, które mają zapobiec udostępnieniu danych osobom nieupoważnionym. Kontrolujemy ich wykonywanie i stale sprawdzamy ich zgodność z odpowiednimi aktami prawnymi - ustawą o ochronie danych osobowych, ustawą o świadczeniu usług drogą elektroniczną, a także wszelkiego rodzaju aktach wykonawczych i aktach prawa wspólnotowego.
    </p>
    <p>

    4. Dane Osobowe przetwarzane są na podstawie zgody wyrażanej przez Użytkownika oraz w przypadkach, w których przepisy prawa upoważniają Administratora do przetwarzania danych osobowych na podstawie przepisów prawa lub w celu realizacji zawartej pomiędzy stronami umowy.
    </p>
    <p>

    5. Serwis realizuje funkcje pozyskiwania informacji o użytkownikach i ich zachowaniach w następujący sposób:
    poprzez dobrowolnie wprowadzone w formularzach informacje
      poprzez gromadzenie plików “cookies” <a href="/cookies">[patrz polityka plików “cookies”].</a>
    </p>
    <p>

    6. Serwis zbiera informacje dobrowolnie podane przez użytkownika.
    </p>
    <p>

    7. dane podane w formularzu są przetwarzane w celu wynikającym z funkcji konkretnego formularza np. w celu dokonania procesu obsługi kontaktu informacyjnego
    </p>
    <p>

    8. Dane osobowe pozostawione w serwisie nie zostaną sprzedane ani udostępnione osobom trzecim, zgodnie z przepisami Ustawy o ochronie danych osobowych.
    </p>
    <p>

    9. Do danych zawartych w formularzu przysługuje wgląd osobie fizycznej, która je tam umieściła. Osoba ta ma również praw do modyfikacji i zaprzestania przetwarzania swoich danych w dowolnym momencie.
    </p>
    <p>

      10. Zastrzegamy sobie prawo do zmiany w polityce ochrony prywatności serwisu, na które może wpłynąć rozwój
      technologii internetowej, ewentualne zmiany prawa w zakresie ochrony danych osobowych oraz rozwój naszego serwisu
      internetowego. O wszelkich zmianach będziemy informować w sposób widoczny i zrozumiały.
    </p>
    <p>

      11. W Serwisie mogą pojawiać się linki do innych stron internetowych. Takie strony internetowe działają
      niezależnie od Serwisu i nie są w żaden sposób nadzorowane przez serwis jaty.pl Strony te mogą posiadać własne
      polityki dotyczące prywatności oraz regulaminy, z którymi zalecamy się zapoznać.
      W razie wątpliwości co któregokolwiek z zapisów niniejszej polityki prywatności jesteśmy do dyspozycji - nasze
      dane znaleźć można w zakładce - KONTAKT.
    </p>

    <p>
      12. Użytkownik w każdej chwili może poprosić o usunięcie wszystkich swoich danych powiązanych z kontem poprzez kontakt z nami przez
      formularz kontaktowy dostępny w panelu klienta w sekcji "Centrum Pomocy > Kontakt z nami", lub poprzez kontakt pod
      adresem <a href="https://jaty.pl/kontakt">https://jaty.pl/kontakt</a>
    </p>
  `,

  cookies_content: `
    <h2> Polityka plików “cookies” serwisu jaty.pl</h2>

    <p>
      1. Poprzez piki “cookies” należy rozumieć dane informatyczne przechowywane w urządzeniach końcowych użytkowników,
      przeznaczone do korzystania ze stron internetowych. W szczególności są to pliki tekstowe, zawierające nazwę strony
      internetowej, z której pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer.
    </p>
    <p> 2. Pliki cookies przeznaczone są do korzystania ze stron serwisu. Operator wykorzystuje te pliki do:<br>
      możliwości logowania i utrzymywania sesji użytkownika na każdej kolejnej stronie serwisu<br>
      dopasowania zawartości strony internetowej do indywidualnych preferencji użytkownika, przede wszystkim pliki te
      rozpoznają jego urządzenie, aby zgodnie z jego preferencjami wyświetlić stronę<br>
      do tworzenia anonimowych statystyk z wyłączeniem możliwości identyfikacji użytkownika.
    </p>
    <p>
      4. Pliki cookies wykorzystywane przez partnerów operatora strony internetowej, w tym w szczególności użytkowników
      strony internetowej, podlegają ich własnej polityce prywatności.
    </p>
    <p>
      5. W trosce o bezpieczeństwo powierzonych nam danych opracowaliśmy wewnętrzne procedury i zalecenia, które mają
      zapobiec udostępnieniu danych osobom nieupoważnionym. Kontrolujemy ich wykonywanie i stale sprawdzamy ich zgodność
      z odpowiednimi aktami prawnymi - ustawą o ochronie danych osobowych, ustawą o świadczeniu usług drogą
      elektroniczną, a także wszelkiego rodzaju aktach wykonawczych i aktach prawa wspólnotowego
    </p>
    <p>
      6. Standardowo oprogramowanie służące do przeglądania stron internetowych domyślnie dopuszcza umieszczanie plików
      cookies na urządzeniu końcowym Użytkownika. Ustawienia te mogą zostać zmienione przez Użytkownika w taki sposób,
      aby blokować automatyczną obsługę “cookies” w ustawieniach przeglądarki internetowej bądź informować o ich
      każdorazowym przesłaniu na urządzenia użytkownika.

    </p>
    <p>
      7. Użytkownicy Serwisu mogą dokonać w każdym czasie zmiany ustawień dotyczących plików cookies. Szczegółowe
      informacje o możliwości i sposobach obsługi plików cookies dostępne są w ustawieniach oprogramowania (przeglądarki
      internetowej).
      Przykładowe opcje edytowania w popularnych przeglądarkach:
      Mozilla Firefox: <a href="www.support.mozilla.org/pl/kb/ciasteczka" target="_blank" rel="noreferrer nofollow">www.support.mozilla.org/pl/kb/ciasteczka </a><br>
      Internet Explorer:<a href="www.support.microsoft.com/kb/278835/pl" target="_blank" rel="noreferrer nofollow">www.support.microsoft.com/kb/278835/pl</a><br>
      Google Chrome: <a href="www.support.google.com/chrome/bin/answer.py?hl=pl&answer=95647" target="_blank" rel="noreferrer nofollow">www.support.google.com/chrome/bin/answer.py?hl=pl&answer=95647</a><br>
      Safari: <a href="www.safari.helpmax.net/pl/oszczedzanie-czasu/blokowanie-zawartosci/" target="_blank" rel="noreferrer nofollow">www.safari.helpmax.net/pl/oszczedzanie-czasu/blokowanie-zawartosci/</a><br>
    </p>
    <p>
      8. Operator Serwisu informuje, że zmiany ustawień w przeglądarce internetowej użytkownika mogą uniemożliwić
      poprawne działanie Stron Internetowych.
    </p>`,

  //shame
  'Normalna': 'Normalna',
  'Muskularna': 'Muskularna',
  'Lekko puszysta': 'Lekko puszysta',
  'Puszysta': 'Puszysta',
  '120 i mniej': '120 i mniej',
  '211 i więcej': '211 i więcej',
  'Posiadam dzieci': 'Posiadam dzieci',
  'Chce mieć': 'Chce mieć',
  'Jeszcze nie wiem': 'Jeszcze nie wiem',
  'Brązowe (piwne)': 'Brązowe (piwne)',
  'Niebieskie': 'Niebieskie',
  'Szare': 'Szare',
  'Mieszane': 'Mieszane',
  'Panna / Kawaler': 'Panna',
  'Mam żonę': 'Mam żonę',
  'Mam męża': 'Mam męża',
  'Rozwódka / Rozwodnik': 'Rozwiedziona',
  'Wdowa / Wdowiec': 'Wdowa',
  'Separacja': 'Separacja',
  'Uczeń / Student': 'Uczeń / Student',
  'Emeryt': 'Emeryt',
  'Rencista': 'Rencista',
  'Niepracujący': 'Niepracujący',
  'Przedsiębiorca': 'Przedsiębiorca',
  'Usługi': 'Usługi',
  'Handel': 'Handel',
  'Pracownik biurowy': 'Pracownik biurowy',
  'Pracownik fizyczny': 'Pracownik fizyczny',
  'Służba mundurowa': 'Służba mundurowa',
  'Służba zdrowia': 'Służba zdrowia',
  'Edukacja': 'Edukacja',
  'Kierownik / Manager': 'Kierownik / Manager',
  'Dyrektor / Prezes': 'Dyrektor / Prezes',
  'Działalność artystyczna': 'Działalność artystyczna',
  'Działalność publiczna': 'Działalność publiczna',
  'Marketingowiec / PR\'owiec': 'Marketingowiec / PR\'owiec',
  'Działalność naukowa': 'Działalność naukowa',
  'Inne': 'Inne',
  'Bardzo chcę małżeństwa': 'Bardzo chcę małżeństwa',
  'Dopuszczam myśl o małżeństwie': 'Dopuszczam myśl o małżeństwie',
  'Nie chcę małżeństwa': 'Nie chcę małżeństwa',
  'Podstawowe': 'Podstawowe',
  'Średnie': 'Średnie',
  'Pomaturalne': 'Pomaturalne',
  'Licencjat': 'Licencjat',
  'Podyplomowe': 'Podyplomowe',
  'Wyższe niepełne': 'Wyższe niepełne',
  'Wyższe': 'Wyższe',
  'Katolicyzm': 'Katolicyzm',
  'Prawosławie': 'Prawosławie',
  'Protestantyzm': 'Protestantyzm',
  'Świadek Jechowy': 'Świadek Jechowy',
  'Buddyzm': 'Buddyzm',
  'Hinduizm': 'Hinduizm',
  'Islam': 'Islam',
  'Judaizm': 'Judaizm',
  'Ateizm': 'Ateizm',
  'Nie chcę mówić': 'Nie chcę mówić',
  'Inna': 'Inna',

  images: {
    hero: '/portal-randkowy/assets/img/hero.webp',
    mobile_jaty: '/portal-randkowy/assets/img/mobile-jaty.webp',
    chat: '/portal-randkowy/assets/img/chat.webp',
    play_badge: '/pl/google/play_badge.svg'
  }
}
