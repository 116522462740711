import Echo from 'laravel-echo'

window.Pusher = require('pusher-js')

export default function (context, inject) {
  var echo = new Echo({
    broadcaster: 'pusher',
    key: context.env.PUSHER_KEY,
    cluster: context.env.PUSHER_CLUSTER,
    forceTLS: true,
    authEndpoint: context.env.ECHO_AUTH_ENDPOINT,
    authorizer: (channel, options) => {
      return {
        authorize: (socketId, callback) => {
          context.$axios.post(options.authEndpoint, {
            socket_id: socketId,
            channel_name: channel.name,
          })
            .then(response => {
              callback(false, response.data)
            })
            .catch(error => {
              callback(true, error)
            })
        }
      }
    }
  })

  context.$echo = echo
  inject('echo', echo)
}
