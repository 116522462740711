import isbot from 'isbot'

const parser = require('accept-language-parser')
const acceptedLangs = ['en', 'pl', 'uk']
const defaultLang = 'en'

const getHeader = (req, header) => req.headers[Object.keys(req.headers).find(key => key.toLowerCase() === header)]

export default function ({ app, req, redirect, route, $auth }) {
  if ($auth.user && $auth.user.lang !== app.i18n.locale) {
    app.$cookiz.set('preffered-lang', $auth.user.lang, { path: '/', maxAge: 60 * 60 * 24 * 365 })
    app.i18n.setLocale($auth.user.lang)
    return
  }

  if (typeof req !== 'undefined' && !isbot(getHeader(req, 'user-agent'))) {

    const acceptedLangHeader = getHeader(req, 'accept-language').toLowerCase()

    if (!acceptedLangHeader) {
      return
    }

    let prefferedLang = app.$cookiz.get('preffered-lang')

    if (!prefferedLang) {
      prefferedLang = defaultLang

      for (let lang of parser.parse(acceptedLangHeader)) {
        if (acceptedLangs.includes(lang.code)) {
          prefferedLang = lang.code
          break
        }
      }

      app.$cookiz.set('preffered-lang', prefferedLang, { path: '/', maxAge: 60 * 60 * 24 * 365 })
    }

    if (app.i18n.locale !== prefferedLang) {
      app.i18n.setLocale(prefferedLang)
    }
  }
}
