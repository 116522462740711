export default {
  data () {
    return {
      deferredPrompt: null
    }
  },
  mounted () {
    console.debug('[pwaControl.js] mounted()')

    this.blockPwaPromptOnAndorid()
  },
  methods: {
    blockPwaPromptOnAndorid () {
      if (this.isAndroid()) {
        window.addEventListener('beforeinstallprompt', function (event) {
          event.preventDefault()
          this.deferredPrompt = event
          return false
        })
      }
    }
  },
}
