export const Alert = () => import('../../components/Alert.vue' /* webpackChunkName: "components/alert" */).then(c => wrapFunctional(c.default || c))
export const Alerts = () => import('../../components/Alerts.vue' /* webpackChunkName: "components/alerts" */).then(c => wrapFunctional(c.default || c))
export const Chat = () => import('../../components/Chat.vue' /* webpackChunkName: "components/chat" */).then(c => wrapFunctional(c.default || c))
export const EmptyList = () => import('../../components/EmptyList.vue' /* webpackChunkName: "components/empty-list" */).then(c => wrapFunctional(c.default || c))
export const FacebookButton = () => import('../../components/FacebookButton.vue' /* webpackChunkName: "components/facebook-button" */).then(c => wrapFunctional(c.default || c))
export const FullscrPremiumBox = () => import('../../components/FullscrPremiumBox.vue' /* webpackChunkName: "components/fullscr-premium-box" */).then(c => wrapFunctional(c.default || c))
export const GooglePlayBadge = () => import('../../components/GooglePlayBadge.vue' /* webpackChunkName: "components/google-play-badge" */).then(c => wrapFunctional(c.default || c))
export const HelpAside = () => import('../../components/HelpAside.vue' /* webpackChunkName: "components/help-aside" */).then(c => wrapFunctional(c.default || c))
export const Loader = () => import('../../components/Loader.vue' /* webpackChunkName: "components/loader" */).then(c => wrapFunctional(c.default || c))
export const ModalForm = () => import('../../components/ModalForm.vue' /* webpackChunkName: "components/modal-form" */).then(c => wrapFunctional(c.default || c))
export const NotificationBox = () => import('../../components/NotificationBox.vue' /* webpackChunkName: "components/notification-box" */).then(c => wrapFunctional(c.default || c))
export const NotificationOffBox = () => import('../../components/NotificationOffBox.vue' /* webpackChunkName: "components/notification-off-box" */).then(c => wrapFunctional(c.default || c))
export const NuxtLinkWithRefresh = () => import('../../components/NuxtLinkWithRefresh.vue' /* webpackChunkName: "components/nuxt-link-with-refresh" */).then(c => wrapFunctional(c.default || c))
export const OfflineTooltip = () => import('../../components/OfflineTooltip.vue' /* webpackChunkName: "components/offline-tooltip" */).then(c => wrapFunctional(c.default || c))
export const Pagination = () => import('../../components/Pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c))
export const PairBox = () => import('../../components/PairBox.vue' /* webpackChunkName: "components/pair-box" */).then(c => wrapFunctional(c.default || c))
export const PremiumBox1 = () => import('../../components/PremiumBox1.vue' /* webpackChunkName: "components/premium-box1" */).then(c => wrapFunctional(c.default || c))
export const PremiumCrown = () => import('../../components/PremiumCrown.vue' /* webpackChunkName: "components/premium-crown" */).then(c => wrapFunctional(c.default || c))
export const SidebarChat = () => import('../../components/SidebarChat.vue' /* webpackChunkName: "components/sidebar-chat" */).then(c => wrapFunctional(c.default || c))
export const SimplePagination = () => import('../../components/SimplePagination.vue' /* webpackChunkName: "components/simple-pagination" */).then(c => wrapFunctional(c.default || c))
export const UserEditAside = () => import('../../components/UserEditAside.vue' /* webpackChunkName: "components/user-edit-aside" */).then(c => wrapFunctional(c.default || c))
export const WebPushBell = () => import('../../components/WebPushBell.vue' /* webpackChunkName: "components/web-push-bell" */).then(c => wrapFunctional(c.default || c))
export const AdsElasticFullWidth = () => import('../../components/ads/elasticFullWidth.vue' /* webpackChunkName: "components/ads-elastic-full-width" */).then(c => wrapFunctional(c.default || c))
export const AdsElasticMobile = () => import('../../components/ads/elasticMobile.vue' /* webpackChunkName: "components/ads-elastic-mobile" */).then(c => wrapFunctional(c.default || c))
export const AdsUsersFeedDesktop = () => import('../../components/ads/usersFeedDesktop.vue' /* webpackChunkName: "components/ads-users-feed-desktop" */).then(c => wrapFunctional(c.default || c))
export const AdsUsersFeedMobile = () => import('../../components/ads/usersFeedMobile.vue' /* webpackChunkName: "components/ads-users-feed-mobile" */).then(c => wrapFunctional(c.default || c))
export const ChatMessage = () => import('../../components/chat/Message.vue' /* webpackChunkName: "components/chat-message" */).then(c => wrapFunctional(c.default || c))
export const ChatUser = () => import('../../components/chat/User.vue' /* webpackChunkName: "components/chat-user" */).then(c => wrapFunctional(c.default || c))
export const InputsAgepicker = () => import('../../components/inputs/Agepicker.vue' /* webpackChunkName: "components/inputs-agepicker" */).then(c => wrapFunctional(c.default || c))
export const InputsBackendErrors = () => import('../../components/inputs/BackendErrors.vue' /* webpackChunkName: "components/inputs-backend-errors" */).then(c => wrapFunctional(c.default || c))
export const InputsCheckboxes = () => import('../../components/inputs/Checkboxes.vue' /* webpackChunkName: "components/inputs-checkboxes" */).then(c => wrapFunctional(c.default || c))
export const InputsCityChooser = () => import('../../components/inputs/CityChooser.vue' /* webpackChunkName: "components/inputs-city-chooser" */).then(c => wrapFunctional(c.default || c))
export const InputsDatepicker = () => import('../../components/inputs/Datepicker.vue' /* webpackChunkName: "components/inputs-datepicker" */).then(c => wrapFunctional(c.default || c))
export const InputsEmojiPicker = () => import('../../components/inputs/EmojiPicker.vue' /* webpackChunkName: "components/inputs-emoji-picker" */).then(c => wrapFunctional(c.default || c))
export const InputsInput = () => import('../../components/inputs/Input.vue' /* webpackChunkName: "components/inputs-input" */).then(c => wrapFunctional(c.default || c))
export const InputsInputWithEmojiPicker = () => import('../../components/inputs/InputWithEmojiPicker.vue' /* webpackChunkName: "components/inputs-input-with-emoji-picker" */).then(c => wrapFunctional(c.default || c))
export const InputsRangePicker = () => import('../../components/inputs/RangePicker.vue' /* webpackChunkName: "components/inputs-range-picker" */).then(c => wrapFunctional(c.default || c))
export const InputsSelect = () => import('../../components/inputs/Select.vue' /* webpackChunkName: "components/inputs-select" */).then(c => wrapFunctional(c.default || c))
export const InputsSexChooser = () => import('../../components/inputs/SexChooser.vue' /* webpackChunkName: "components/inputs-sex-chooser" */).then(c => wrapFunctional(c.default || c))
export const InputsTagChooser = () => import('../../components/inputs/TagChooser.vue' /* webpackChunkName: "components/inputs-tag-chooser" */).then(c => wrapFunctional(c.default || c))
export const InputsTextarea = () => import('../../components/inputs/Textarea.vue' /* webpackChunkName: "components/inputs-textarea" */).then(c => wrapFunctional(c.default || c))
export const UserAvatar = () => import('../../components/user/Avatar.vue' /* webpackChunkName: "components/user-avatar" */).then(c => wrapFunctional(c.default || c))
export const UserCard = () => import('../../components/user/Card.vue' /* webpackChunkName: "components/user-card" */).then(c => wrapFunctional(c.default || c))
export const UserCardFake = () => import('../../components/user/CardFake.vue' /* webpackChunkName: "components/user-card-fake" */).then(c => wrapFunctional(c.default || c))
export const UserChatIcon = () => import('../../components/user/ChatIcon.vue' /* webpackChunkName: "components/user-chat-icon" */).then(c => wrapFunctional(c.default || c))
export const UserDislikeIcon = () => import('../../components/user/DislikeIcon.vue' /* webpackChunkName: "components/user-dislike-icon" */).then(c => wrapFunctional(c.default || c))
export const UserDropdown = () => import('../../components/user/Dropdown.vue' /* webpackChunkName: "components/user-dropdown" */).then(c => wrapFunctional(c.default || c))
export const UserLikeIcon = () => import('../../components/user/LikeIcon.vue' /* webpackChunkName: "components/user-like-icon" */).then(c => wrapFunctional(c.default || c))
export const UserMiniCard = () => import('../../components/user/MiniCard.vue' /* webpackChunkName: "components/user-mini-card" */).then(c => wrapFunctional(c.default || c))
export const UserShowIcons = () => import('../../components/user/ShowIcons.vue' /* webpackChunkName: "components/user-show-icons" */).then(c => wrapFunctional(c.default || c))
export const UserViews = () => import('../../components/user/Views.vue' /* webpackChunkName: "components/user-views" */).then(c => wrapFunctional(c.default || c))
export const CurrentUserPaymentsCardHeader = () => import('../../components/current-user/payments/CardHeader.vue' /* webpackChunkName: "components/current-user-payments-card-header" */).then(c => wrapFunctional(c.default || c))
export const CurrentUserPaymentsPremiumTable = () => import('../../components/current-user/payments/PremiumTable.vue' /* webpackChunkName: "components/current-user-payments-premium-table" */).then(c => wrapFunctional(c.default || c))
export const CurrentUserPaymentsPremiumTo = () => import('../../components/current-user/payments/PremiumTo.vue' /* webpackChunkName: "components/current-user-payments-premium-to" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
