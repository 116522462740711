export const state = () => ({
  users: {},
  countNotRead: {},
  totalNotRead: 0,
  sidebarTriggered: false
})

export const mutations = {
  SET_USERS (state, users) {
    state.users = users
  },
  SET_COUNT_NOT_READ (state, countNotRead) {
    state.totalNotRead = Object.keys(countNotRead.count).length
    state.countNotRead = countNotRead
  },
  TOGGLE_SIDEBAR_TRIGGERED (state) {
    state.sidebarTriggered = !state.sidebarTriggered
  },
  SET_SIDEBAR_TRIGGERED (state, sidebarTriggered) {
    state.sidebarTriggered = sidebarTriggered
  },
  MOVE_USER_TO_TOP (state, userId) {
      state.users.sort((x, y) => { return x.id == userId ? -1 : y.id == userId ? 1 : 0 })
  },
  READ_FOR_USER (state, userId) {
    if (state.countNotRead.count[userId] != undefined) {
      state.countNotRead.count[userId] = 0
      delete state.countNotRead.count[userId]
    }

    state.totalNotRead = Object.keys(state.countNotRead.count).length
  }
}

export const actions = {
  async fetchUsers ({ commit }) {
    try {
      let chatUsersResponse = await this.$axios.get('/api/chat/users')
      commit('SET_USERS', chatUsersResponse.data)

      let countNotReadResponse = await this.$axios.get('/api/messages/count-not-read-for-all-users')
      commit('SET_COUNT_NOT_READ', countNotReadResponse.data)
    } catch (e) {
      this.$sentry.setContext('fetch user error', {
        'user_id': this.$auth.user.id
      })

      this.$sentry.captureException(e)
    }
  },
  async fetchCountNotRead ({ commit }) {
    let countNotReadResponse = await this.$axios.get('/api/messages/count-not-read-for-all-users')
    commit('SET_COUNT_NOT_READ', countNotReadResponse.data)
  },
  toggleSidebarTriggered ({ commit }) {
    commit('TOGGLE_SIDEBAR_TRIGGERED')
  },
  setSidebarTriggered ({ commit }, sidebarTriggered) {
    commit('SET_SIDEBAR_TRIGGERED', sidebarTriggered)
  },
  moveUserToTop ({ commit }, userId) {
    commit('MOVE_USER_TO_TOP', userId)
  },
  async readForUser ({ commit, state, dispatch }, userId) {
    // if added due sentry errors dunno why
    if (!state.countNotRead) {
      await dispatch('fetchUsers')
    }

    commit('READ_FOR_USER', userId)
  }
}

