export const state = () => ({
  users: [],
  noMoreUsers: false
})

export const mutations = {
  SET_USERS (state, users) {
    state.users = users
  },
  ADD_USER (state, user) {
    state.users.push(user)
  },
  REMOVE_USER (state, id) {
    if (state.users) {
      let index = state.users.findIndex(user => user.id == id)
      state.users.splice(index, 1)
    }
  },
  SET_NO_MORE_USERS (state, noMoreUsers) {
    state.noMoreUsers = noMoreUsers
  }
}

export const actions = {
  async fetch ({ commit }, searchParameters) {
    const response = await this.$axios.get('api/users', {
      params: {
        radius: searchParameters.radius,
        place_id: searchParameters.city.place_id,
        place_main: searchParameters.city.main,
        city: searchParameters.city.description
      }
    })
    commit('SET_USERS', response.data)

    if (response.data.length < 20) {
      commit('SET_NO_MORE_USERS', true)
      return
    }

    commit('SET_NO_MORE_USERS', false)
  },
  async fetchOne ({ commit, state }, searchParameters) {
    const response = await this.$axios.get('api/users', {
      params: {
        limit: 1,
        radius: searchParameters.radius,
        place_id: searchParameters.city.place_id,
        place_main: searchParameters.city.main,
        city: searchParameters.city.description,
        offset: state.users.length + searchParameters.offsetModifer
      }
    })
    if (response.data.length === 0) {
      commit('SET_NO_MORE_USERS', true)
      return
    }

    commit('SET_NO_MORE_USERS', false)
    commit('ADD_USER', response.data[0])
  },
  async fetchNew ({ commit, state }, searchParameters) {
    if (state.noMoreUsers) {
      return
    }

    const response = await this.$axios.get('api/users', {
      params: {
        radius: searchParameters.radius,
        place_id: searchParameters.city.place_id,
        place_main: searchParameters.city.main,
        city: searchParameters.city.description,
        limit: 20,
        offset: state.users.length
      }
    })

    if (response.data.length === 0) {
      commit('SET_NO_MORE_USERS', true)
      return
    }

    commit('SET_NO_MORE_USERS', false)
    response.data.forEach((user) => {
      commit('ADD_USER', user)
    })
  },
  clear ({ commit }) {
    commit('SET_USERS', {})
  },
  remove ({ commit }, user) {
    commit('SET_NO_MORE_USERS', false)
    commit('REMOVE_USER', user.id)
  }
}

