export default function ({ app, $auth, redirect }) {
  console.debug('[userFull.js] (if) !$auth.user  (then) this.$router.push(\'/logout\')')
  if (!$auth.user) {
    return $auth.fetchUser().then(() => {
      if (!$auth.user) {
        this.$router.push(this.localePath('/logout'))
      }
    })
  }

  console.debug('[userFull.js] (if) !$auth.user.born_at  (then) return redirect(\'/welcome\')')
  if (!$auth.user.born_at) {
    return $auth.fetchUser().then(() => {
      if (!$auth.user.born_at) {
        return redirect(app.localePath('/welcome'))
      }
    })
  }
}
