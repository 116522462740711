export const state = () => ({
  nonPremiumLimits: {}
})

export const mutations = {
  SET_NON_PREMIUM_LIMITS (state, nonPremiumLimits) {
    state.nonPremiumLimits = nonPremiumLimits
  }
}

export const actions = {
  async fetch ({ commit }) {
    const response = await this.$axios.get('api/non-premium-limits/')
    commit('SET_NON_PREMIUM_LIMITS', response.data)
  }
}

export const getters = {
}

