import moment from 'moment'
import { localizeVeeValidate } from '~/plugins/vee-validate'

export default function ({ app }) {
  // onLanguageSwitched called right after a new locale has been set
  app.i18n.onBeforeLanguageSwitch = (oldLocale, newLocale) => {
    app.$cookiz.set('preffered-lang', newLocale, { path: '/', maxAge: 60 * 60 * 24 * 365 })

    if (newLocale === 'pl') {
      const { pl } = require('moment/locale/pl')
      moment.locale(pl)
    } else if (newLocale === 'en') {
      const { en } = require('moment/locale/en-gb')
      moment.locale(en)
    } else if (newLocale === 'uk') {
      const { ru } = require('moment/locale/uk')
      moment.locale(ru)
    }

    localizeVeeValidate(newLocale)

  }
}
