import Vue from 'vue'
import moment from 'moment'

Vue.platformDetector = true
Vue.mixin({
  data () {
    return {
      displayMode: 'browser',
      oneSecToggler: true,
      isDev: false,

    }
  },
  created () {
    this.isDev = process.env.NODE_ENV !== 'production'
  },
  mounted () {
    console.debug('[platform-detector.js][mounted()]')

    this.displayMode = this.getDisplayMode()

    setInterval(() => {
      this.oneSecToggler = !this.oneSecToggler
    }, 1000)
  },
  computed: {
    servicePlayBilling () {
      return this.$store.state.servicePlayBilling.service
    },
    isTWA () {
      return Boolean(this.hasGetDigitalGoodsService() && 'standalone' === this.getDisplayMode() && this.servicePlayBilling)
    },
    paymentUrl () {
      if (this.isTWA) {
        return this.getTWAPaymentUrl()
      }

      if (this.countdown) {
        return this.getTimePromoPaymentUrl()
      }

      return this.getNormalPaymentUrl()
    },
    countdown () {
      let oneSecToggler = this.oneSecToggler
      const startAt = moment(this.$auth.user.time_promo_started_at)
      const endAt = startAt.add(1, 'hours')
      const now = moment()
      if (!this.$auth.user.time_promo_started_at || endAt.diff(now, 'seconds') <= 0) {
        return false
      }
      let seconds = (endAt.diff(now, 'seconds') % 60)
      if (seconds < 10) {
        seconds = '0' + seconds
      }

      let minutes = (endAt.diff(now, 'minutes') % 60)
      if (minutes < 10) {
        minutes = '0' + minutes
      }

      let hours = (endAt.diff(now, 'hours'))
      if (hours < 10) {
        hours = '0' + hours
      }

      return hours + 'h ' + minutes + 'm ' + seconds + 's'
    }
  },
  methods: {
    getTWAPaymentUrl () {
      return this.localePath('/current-user/payments/digital')
    },
    getTimePromoPaymentUrl () {
      return this.localePath('/current-user/payments/time-promo')
    },
    getNormalPaymentUrl () {
      return this.localePath('/current-user/payments')
    },
    isAndroid () {
      var userAgent = navigator.userAgent.toLowerCase()

      return userAgent.indexOf('android') > -1
    },
    hasGetDigitalGoodsService () {
      if (typeof window === 'undefined') {
        return false
      }
      ('getDigitalGoodsService' in window) ? console.debug('getDigitalGoodsService in window') : console.debug('getDigitalGoodsService not in window')
      return ('getDigitalGoodsService' in window)
    },
    getDisplayMode () {
      if (navigator.standalone || window.matchMedia('(display-mode: standalone)').matches) {
        return 'standalone'
      }

      return 'browser'
    }
  }
})


