import locale77dc9845 from '../../locales/pl.js'
import locale77427e78 from '../../locales/en.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"pl"},
  vueI18nLoader: false,
  locales: [{"code":"en","iso":"en-US","file":"en.js"},{"code":"pl","iso":"pl-PL","file":"pl.js"}],
  defaultLocale: "pl",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: false,
  langDir: "~/locales/",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en","iso":"en-US","file":"en.js"},{"code":"pl","iso":"pl-PL","file":"pl.js"}],
  localeCodes: ["en","pl"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "e",
  3: "n",
  4: ".",
  5: "j",
  6: "s",
  7: "\"",
  8: ":",
  9: "\"",
  10: ".",
  11: ".",
  12: "/",
  13: "l",
  14: "o",
  15: "c",
  16: "a",
  17: "l",
  18: "e",
  19: "s",
  20: "/",
  21: "e",
  22: "n",
  23: ".",
  24: "j",
  25: "s",
  26: "\"",
  27: ",",
  28: "\"",
  29: "p",
  30: "l",
  31: ".",
  32: "j",
  33: "s",
  34: "\"",
  35: ":",
  36: "\"",
  37: ".",
  38: ".",
  39: "/",
  40: "l",
  41: "o",
  42: "c",
  43: "a",
  44: "l",
  45: "e",
  46: "s",
  47: "/",
  48: "p",
  49: "l",
  50: ".",
  51: "j",
  52: "s",
  53: "\"",
  54: "}",
}

export const localeMessages = {
  'pl.js': () => Promise.resolve(locale77dc9845),
  'en.js': () => Promise.resolve(locale77427e78),
}
