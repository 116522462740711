import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_fe1bf724 from 'nuxt_plugin_plugin_fe1bf724' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_sentryserver_33abe048 from 'nuxt_plugin_sentryserver_33abe048' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_641b2280 from 'nuxt_plugin_sentryclient_641b2280' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_cookieuniversalnuxt_454607a8 from 'nuxt_plugin_cookieuniversalnuxt_454607a8' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_pluginutils_5062bb92 from 'nuxt_plugin_pluginutils_5062bb92' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_56a1047c from 'nuxt_plugin_pluginrouting_56a1047c' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_371614ed from 'nuxt_plugin_pluginmain_371614ed' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_nuxtsvgsprite_0c29eaa2 from 'nuxt_plugin_nuxtsvgsprite_0c29eaa2' // Source: ./nuxt-svg-sprite.js (mode: 'all')
import nuxt_plugin_vuescrollto_37e0c505 from 'nuxt_plugin_vuescrollto_37e0c505' // Source: ./vue-scrollto.js (mode: 'client')
import nuxt_plugin_axios_2f14e484 from 'nuxt_plugin_axios_2f14e484' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_buefy_1e183856 from 'nuxt_plugin_buefy_1e183856' // Source: ./buefy.js (mode: 'all')
import nuxt_plugin_workbox_3f3e48a0 from 'nuxt_plugin_workbox_3f3e48a0' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_metaplugin_66d6209a from 'nuxt_plugin_metaplugin_66d6209a' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_googleanalytics_41d58dbc from 'nuxt_plugin_googleanalytics_41d58dbc' // Source: ./google-analytics.js (mode: 'client')
import nuxt_plugin_i18n_66ff12a5 from 'nuxt_plugin_i18n_66ff12a5' // Source: ../plugins/i18n.js (mode: 'all')
import nuxt_plugin_echo_29455d84 from 'nuxt_plugin_echo_29455d84' // Source: ../plugins/echo.js (mode: 'client')
import nuxt_plugin_moment_04f7b483 from 'nuxt_plugin_moment_04f7b483' // Source: ../plugins/moment.js (mode: 'all')
import nuxt_plugin_axios_397e53b5 from 'nuxt_plugin_axios_397e53b5' // Source: ../plugins/axios.js (mode: 'all')
import nuxt_plugin_veevalidate_21716614 from 'nuxt_plugin_veevalidate_21716614' // Source: ../plugins/vee-validate.js (mode: 'all')
import nuxt_plugin_urlhelper_710de337 from 'nuxt_plugin_urlhelper_710de337' // Source: ../plugins/url-helper.js (mode: 'all')
import nuxt_plugin_cookie_a3f0bcc2 from 'nuxt_plugin_cookie_a3f0bcc2' // Source: ../plugins/cookie.js (mode: 'all')
import nuxt_plugin_platformdetector_e75243ea from 'nuxt_plugin_platformdetector_e75243ea' // Source: ../plugins/platform-detector.js (mode: 'all')
import nuxt_plugin_hotjar_cc856dd6 from 'nuxt_plugin_hotjar_cc856dd6' // Source: ../plugins/hotjar.js (mode: 'client')
import nuxt_plugin_gtag_71c5e2d0 from 'nuxt_plugin_gtag_71c5e2d0' // Source: ../plugins/gtag.js (mode: 'client')
import nuxt_plugin_auth_fdc3b7a8 from 'nuxt_plugin_auth_fdc3b7a8' // Source: ./auth.js (mode: 'all')
import nuxt_plugin_authredirect_4c7e876c from 'nuxt_plugin_authredirect_4c7e876c' // Source: ../plugins/auth-redirect.js (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const store = createStore(ssrContext)
  const router = await createRouter(ssrContext, config, { store })

  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"jaty-frontend","script":[{"async":"async","src":"https:\u002F\u002Fwww.googletagmanager.com\u002Fgtag\u002Fjs?id=AW-344870238"}],"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, user-scalable=0, minimum-scale=1, maximum-scale=1"},{"hid":"description","name":"description","content":""},{"name":"apple-mobile-web-app-capable","content":"yes"},{"name":"apple-mobile-web-app-title","content":"JaTy"},{"http-equiv":"origin-trial","content":"Amv\u002FFftbOzU3go2GtRTogkZpUbr9NJ5sarrwz5wY\u002FfwSeh+GbpU8j4ESTqnlOc2xzX\u002FgrkT96sW5PZydJJTUnQYAAABPeyJvcmlnaW4iOiJodHRwczovL2phdHkucGw6NDQzIiwiZmVhdHVyZSI6IkRpZ2l0YWxHb29kc1YyIiwiZXhwaXJ5IjoxNjUzMjYzOTk5fQ=="}],"link":[{"rel":"manifest","href":"\u002Fmanifest.json"},{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"},{"rel":"apple-touch-icon","href":"\u002Ficons\u002Fapple_icon.png"}],"style":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_fe1bf724 === 'function') {
    await nuxt_plugin_plugin_fe1bf724(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_sentryserver_33abe048 === 'function') {
    await nuxt_plugin_sentryserver_33abe048(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_641b2280 === 'function') {
    await nuxt_plugin_sentryclient_641b2280(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_454607a8 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_454607a8(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_5062bb92 === 'function') {
    await nuxt_plugin_pluginutils_5062bb92(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_56a1047c === 'function') {
    await nuxt_plugin_pluginrouting_56a1047c(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_371614ed === 'function') {
    await nuxt_plugin_pluginmain_371614ed(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtsvgsprite_0c29eaa2 === 'function') {
    await nuxt_plugin_nuxtsvgsprite_0c29eaa2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuescrollto_37e0c505 === 'function') {
    await nuxt_plugin_vuescrollto_37e0c505(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_2f14e484 === 'function') {
    await nuxt_plugin_axios_2f14e484(app.context, inject)
  }

  if (typeof nuxt_plugin_buefy_1e183856 === 'function') {
    await nuxt_plugin_buefy_1e183856(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_3f3e48a0 === 'function') {
    await nuxt_plugin_workbox_3f3e48a0(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_66d6209a === 'function') {
    await nuxt_plugin_metaplugin_66d6209a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_googleanalytics_41d58dbc === 'function') {
    await nuxt_plugin_googleanalytics_41d58dbc(app.context, inject)
  }

  if (typeof nuxt_plugin_i18n_66ff12a5 === 'function') {
    await nuxt_plugin_i18n_66ff12a5(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_echo_29455d84 === 'function') {
    await nuxt_plugin_echo_29455d84(app.context, inject)
  }

  if (typeof nuxt_plugin_moment_04f7b483 === 'function') {
    await nuxt_plugin_moment_04f7b483(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_397e53b5 === 'function') {
    await nuxt_plugin_axios_397e53b5(app.context, inject)
  }

  if (typeof nuxt_plugin_veevalidate_21716614 === 'function') {
    await nuxt_plugin_veevalidate_21716614(app.context, inject)
  }

  if (typeof nuxt_plugin_urlhelper_710de337 === 'function') {
    await nuxt_plugin_urlhelper_710de337(app.context, inject)
  }

  if (typeof nuxt_plugin_cookie_a3f0bcc2 === 'function') {
    await nuxt_plugin_cookie_a3f0bcc2(app.context, inject)
  }

  if (typeof nuxt_plugin_platformdetector_e75243ea === 'function') {
    await nuxt_plugin_platformdetector_e75243ea(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_hotjar_cc856dd6 === 'function') {
    await nuxt_plugin_hotjar_cc856dd6(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_gtag_71c5e2d0 === 'function') {
    await nuxt_plugin_gtag_71c5e2d0(app.context, inject)
  }

  if (typeof nuxt_plugin_auth_fdc3b7a8 === 'function') {
    await nuxt_plugin_auth_fdc3b7a8(app.context, inject)
  }

  if (typeof nuxt_plugin_authredirect_4c7e876c === 'function') {
    await nuxt_plugin_authredirect_4c7e876c(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
