export default function ({ app, $auth, redirect, route }) {
  console.debug('[userVerified.js] (if) !$auth.user.email_verified_at  (then)  return redirect(\'/current-user/verify-email?alert=\'+route.query.alert) else return redirect(\'/current-user/verify-email\')')
  if (!$auth.user.email_verified_at) {
    return $auth.fetchUser().then(() => {
      if (!$auth.user.email_verified_at) {
        if (route.query.alert) {
          return redirect(app.localePath('/current-user/verify-email?alert=' + route.query.alert))
        } else {
          return redirect(app.localePath('/current-user/verify-email'))
        }
      }
    })
  }
}
