import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _8fc03cfc = () => interopDefault(import('../pages/alerts/index.vue' /* webpackChunkName: "pages/alerts/index" */))
const _c695f184 = () => interopDefault(import('../pages/components.vue' /* webpackChunkName: "pages/components" */))
const _635177b0 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _663e9317 = () => interopDefault(import('../pages/cookies.vue' /* webpackChunkName: "pages/cookies" */))
const _a4b3884c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _607c1c2e = () => interopDefault(import('../pages/help.vue' /* webpackChunkName: "pages/help" */))
const _f9b8c1b0 = () => interopDefault(import('../pages/kontakt.vue' /* webpackChunkName: "pages/kontakt" */))
const _774ddef1 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _2840f6c4 = () => interopDefault(import('../pages/login-test.vue' /* webpackChunkName: "pages/login-test" */))
const _8101f09c = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _2fab6e12 = () => interopDefault(import('../pages/polityka-prywatnosci.vue' /* webpackChunkName: "pages/polityka-prywatnosci" */))
const _2898e5f8 = () => interopDefault(import('../pages/redirect.vue' /* webpackChunkName: "pages/redirect" */))
const _4d3c084b = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _b5474df8 = () => interopDefault(import('../pages/regulamin.vue' /* webpackChunkName: "pages/regulamin" */))
const _8f7cf89e = () => interopDefault(import('../pages/social-login.vue' /* webpackChunkName: "pages/social-login" */))
const _27fd63cc = () => interopDefault(import('../pages/status.vue' /* webpackChunkName: "pages/status" */))
const _9ffc4974 = () => interopDefault(import('../pages/twa.vue' /* webpackChunkName: "pages/twa" */))
const _db505eda = () => interopDefault(import('../pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _596fd96d = () => interopDefault(import('../pages/welcome/index.vue' /* webpackChunkName: "pages/welcome/index" */))
const _52788a85 = () => interopDefault(import('../pages/current-user/edit/index.vue' /* webpackChunkName: "pages/current-user/edit/index" */))
const _3e326be8 = () => interopDefault(import('../pages/current-user/payments/index.vue' /* webpackChunkName: "pages/current-user/payments/index" */))
const _01a579c0 = () => interopDefault(import('../pages/current-user/verify-email.vue' /* webpackChunkName: "pages/current-user/verify-email" */))
const _46214e83 = () => interopDefault(import('../pages/password/reset.vue' /* webpackChunkName: "pages/password/reset" */))
const _76714f76 = () => interopDefault(import('../pages/password/reset-request.vue' /* webpackChunkName: "pages/password/reset-request" */))
const _8e1df424 = () => interopDefault(import('../pages/users/liked.vue' /* webpackChunkName: "pages/users/liked" */))
const _4cdba223 = () => interopDefault(import('../pages/users/liking.vue' /* webpackChunkName: "pages/users/liking" */))
const _782f451a = () => interopDefault(import('../pages/users/pairs.vue' /* webpackChunkName: "pages/users/pairs" */))
const _39b7fea8 = () => interopDefault(import('../pages/welcome/about.vue' /* webpackChunkName: "pages/welcome/about" */))
const _55af626d = () => interopDefault(import('../pages/welcome/images.vue' /* webpackChunkName: "pages/welcome/images" */))
const _9caaad3c = () => interopDefault(import('../pages/welcome/payments.vue' /* webpackChunkName: "pages/welcome/payments" */))
const _316abaca = () => interopDefault(import('../pages/current-user/edit/delete-request.vue' /* webpackChunkName: "pages/current-user/edit/delete-request" */))
const _7dbcd455 = () => interopDefault(import('../pages/current-user/edit/images.vue' /* webpackChunkName: "pages/current-user/edit/images" */))
const _238773ea = () => interopDefault(import('../pages/current-user/edit/lang.vue' /* webpackChunkName: "pages/current-user/edit/lang" */))
const _5c53dcb3 = () => interopDefault(import('../pages/current-user/edit/notification-preferences.vue' /* webpackChunkName: "pages/current-user/edit/notification-preferences" */))
const _4a8385b8 = () => interopDefault(import('../pages/current-user/edit/password.vue' /* webpackChunkName: "pages/current-user/edit/password" */))
const _db02052a = () => interopDefault(import('../pages/current-user/edit/preferences.vue' /* webpackChunkName: "pages/current-user/edit/preferences" */))
const _e00fe5c8 = () => interopDefault(import('../pages/current-user/edit/profile.vue' /* webpackChunkName: "pages/current-user/edit/profile" */))
const _a71892a4 = () => interopDefault(import('../pages/current-user/payments/digital.vue' /* webpackChunkName: "pages/current-user/payments/digital" */))
const _157fba36 = () => interopDefault(import('../pages/current-user/payments/redirect.vue' /* webpackChunkName: "pages/current-user/payments/redirect" */))
const _1f998323 = () => interopDefault(import('../pages/current-user/payments/thanks.vue' /* webpackChunkName: "pages/current-user/payments/thanks" */))
const _23c42a69 = () => interopDefault(import('../pages/current-user/payments/time-promo.vue' /* webpackChunkName: "pages/current-user/payments/time-promo" */))
const _debda16a = () => interopDefault(import('../pages/users/_slug.vue' /* webpackChunkName: "pages/users/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/alerts",
    component: _8fc03cfc,
    name: "alerts___pl"
  }, {
    path: "/components",
    component: _c695f184,
    name: "components___pl"
  }, {
    path: "/contact",
    component: _635177b0,
    name: "contact___pl"
  }, {
    path: "/cookies",
    component: _663e9317,
    name: "cookies___pl"
  }, {
    path: "/en",
    component: _a4b3884c,
    name: "index___en"
  }, {
    path: "/help",
    component: _607c1c2e,
    name: "help___pl"
  }, {
    path: "/kontakt",
    component: _f9b8c1b0,
    name: "kontakt___pl"
  }, {
    path: "/login",
    component: _774ddef1,
    name: "login___pl"
  }, {
    path: "/login-test",
    component: _2840f6c4,
    name: "login-test___pl"
  }, {
    path: "/logout",
    component: _8101f09c,
    name: "logout___pl"
  }, {
    path: "/polityka-prywatnosci",
    component: _2fab6e12,
    name: "polityka-prywatnosci___pl"
  }, {
    path: "/redirect",
    component: _2898e5f8,
    name: "redirect___pl"
  }, {
    path: "/register",
    component: _4d3c084b,
    name: "register___pl"
  }, {
    path: "/regulamin",
    component: _b5474df8,
    name: "regulamin___pl"
  }, {
    path: "/social-login",
    component: _8f7cf89e,
    name: "social-login___pl"
  }, {
    path: "/status",
    component: _27fd63cc,
    name: "status___pl"
  }, {
    path: "/twa",
    component: _9ffc4974,
    name: "twa___pl"
  }, {
    path: "/users",
    component: _db505eda,
    name: "users___pl"
  }, {
    path: "/welcome",
    component: _596fd96d,
    name: "welcome___pl"
  }, {
    path: "/current-user/edit",
    component: _52788a85,
    name: "current-user-edit___pl"
  }, {
    path: "/current-user/payments",
    component: _3e326be8,
    name: "current-user-payments___pl"
  }, {
    path: "/current-user/verify-email",
    component: _01a579c0,
    name: "current-user-verify-email___pl"
  }, {
    path: "/en/alerts",
    component: _8fc03cfc,
    name: "alerts___en"
  }, {
    path: "/en/components",
    component: _c695f184,
    name: "components___en"
  }, {
    path: "/en/contact",
    component: _635177b0,
    name: "contact___en"
  }, {
    path: "/en/cookies",
    component: _663e9317,
    name: "cookies___en"
  }, {
    path: "/en/help",
    component: _607c1c2e,
    name: "help___en"
  }, {
    path: "/en/kontakt",
    component: _f9b8c1b0,
    name: "kontakt___en"
  }, {
    path: "/en/login",
    component: _774ddef1,
    name: "login___en"
  }, {
    path: "/en/login-test",
    component: _2840f6c4,
    name: "login-test___en"
  }, {
    path: "/en/logout",
    component: _8101f09c,
    name: "logout___en"
  }, {
    path: "/en/polityka-prywatnosci",
    component: _2fab6e12,
    name: "polityka-prywatnosci___en"
  }, {
    path: "/en/redirect",
    component: _2898e5f8,
    name: "redirect___en"
  }, {
    path: "/en/register",
    component: _4d3c084b,
    name: "register___en"
  }, {
    path: "/en/regulamin",
    component: _b5474df8,
    name: "regulamin___en"
  }, {
    path: "/en/social-login",
    component: _8f7cf89e,
    name: "social-login___en"
  }, {
    path: "/en/status",
    component: _27fd63cc,
    name: "status___en"
  }, {
    path: "/en/twa",
    component: _9ffc4974,
    name: "twa___en"
  }, {
    path: "/en/users",
    component: _db505eda,
    name: "users___en"
  }, {
    path: "/en/welcome",
    component: _596fd96d,
    name: "welcome___en"
  }, {
    path: "/password/reset",
    component: _46214e83,
    name: "password-reset___pl"
  }, {
    path: "/password/reset-request",
    component: _76714f76,
    name: "password-reset-request___pl"
  }, {
    path: "/users/liked",
    component: _8e1df424,
    name: "users-liked___pl"
  }, {
    path: "/users/liking",
    component: _4cdba223,
    name: "users-liking___pl"
  }, {
    path: "/users/pairs",
    component: _782f451a,
    name: "users-pairs___pl"
  }, {
    path: "/welcome/about",
    component: _39b7fea8,
    name: "welcome-about___pl"
  }, {
    path: "/welcome/images",
    component: _55af626d,
    name: "welcome-images___pl"
  }, {
    path: "/welcome/payments",
    component: _9caaad3c,
    name: "welcome-payments___pl"
  }, {
    path: "/current-user/edit/delete-request",
    component: _316abaca,
    name: "current-user-edit-delete-request___pl"
  }, {
    path: "/current-user/edit/images",
    component: _7dbcd455,
    name: "current-user-edit-images___pl"
  }, {
    path: "/current-user/edit/lang",
    component: _238773ea,
    name: "current-user-edit-lang___pl"
  }, {
    path: "/current-user/edit/notification-preferences",
    component: _5c53dcb3,
    name: "current-user-edit-notification-preferences___pl"
  }, {
    path: "/current-user/edit/password",
    component: _4a8385b8,
    name: "current-user-edit-password___pl"
  }, {
    path: "/current-user/edit/preferences",
    component: _db02052a,
    name: "current-user-edit-preferences___pl"
  }, {
    path: "/current-user/edit/profile",
    component: _e00fe5c8,
    name: "current-user-edit-profile___pl"
  }, {
    path: "/current-user/payments/digital",
    component: _a71892a4,
    name: "current-user-payments-digital___pl"
  }, {
    path: "/current-user/payments/redirect",
    component: _157fba36,
    name: "current-user-payments-redirect___pl"
  }, {
    path: "/current-user/payments/thanks",
    component: _1f998323,
    name: "current-user-payments-thanks___pl"
  }, {
    path: "/current-user/payments/time-promo",
    component: _23c42a69,
    name: "current-user-payments-time-promo___pl"
  }, {
    path: "/en/current-user/edit",
    component: _52788a85,
    name: "current-user-edit___en"
  }, {
    path: "/en/current-user/payments",
    component: _3e326be8,
    name: "current-user-payments___en"
  }, {
    path: "/en/current-user/verify-email",
    component: _01a579c0,
    name: "current-user-verify-email___en"
  }, {
    path: "/en/password/reset",
    component: _46214e83,
    name: "password-reset___en"
  }, {
    path: "/en/password/reset-request",
    component: _76714f76,
    name: "password-reset-request___en"
  }, {
    path: "/en/users/liked",
    component: _8e1df424,
    name: "users-liked___en"
  }, {
    path: "/en/users/liking",
    component: _4cdba223,
    name: "users-liking___en"
  }, {
    path: "/en/users/pairs",
    component: _782f451a,
    name: "users-pairs___en"
  }, {
    path: "/en/welcome/about",
    component: _39b7fea8,
    name: "welcome-about___en"
  }, {
    path: "/en/welcome/images",
    component: _55af626d,
    name: "welcome-images___en"
  }, {
    path: "/en/welcome/payments",
    component: _9caaad3c,
    name: "welcome-payments___en"
  }, {
    path: "/en/current-user/edit/delete-request",
    component: _316abaca,
    name: "current-user-edit-delete-request___en"
  }, {
    path: "/en/current-user/edit/images",
    component: _7dbcd455,
    name: "current-user-edit-images___en"
  }, {
    path: "/en/current-user/edit/lang",
    component: _238773ea,
    name: "current-user-edit-lang___en"
  }, {
    path: "/en/current-user/edit/notification-preferences",
    component: _5c53dcb3,
    name: "current-user-edit-notification-preferences___en"
  }, {
    path: "/en/current-user/edit/password",
    component: _4a8385b8,
    name: "current-user-edit-password___en"
  }, {
    path: "/en/current-user/edit/preferences",
    component: _db02052a,
    name: "current-user-edit-preferences___en"
  }, {
    path: "/en/current-user/edit/profile",
    component: _e00fe5c8,
    name: "current-user-edit-profile___en"
  }, {
    path: "/en/current-user/payments/digital",
    component: _a71892a4,
    name: "current-user-payments-digital___en"
  }, {
    path: "/en/current-user/payments/redirect",
    component: _157fba36,
    name: "current-user-payments-redirect___en"
  }, {
    path: "/en/current-user/payments/thanks",
    component: _1f998323,
    name: "current-user-payments-thanks___en"
  }, {
    path: "/en/current-user/payments/time-promo",
    component: _23c42a69,
    name: "current-user-payments-time-promo___en"
  }, {
    path: "/en/users/:slug",
    component: _debda16a,
    name: "users-slug___en"
  }, {
    path: "/users/:slug",
    component: _debda16a,
    name: "users-slug___pl"
  }, {
    path: "/",
    component: _a4b3884c,
    name: "index___pl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
