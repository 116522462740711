export const state = () => ({
  options: {},
})

export const mutations = {
  SET_OPTIONS (state, options) {
    state.options = options
  }
}

export const actions = {
  async fetchOptions ({ commit }) {
    const response = await this.$axios.get('api/profiles/options')
    commit('SET_OPTIONS', response.data)
  }
}

