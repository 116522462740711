/**
 * NOTE: this is only used for client side cookies.
 * In future we can potentialy migrate it to already installed lib 'cookie-universal-nuxt'
 */
import Vue from 'vue'

if (!Vue.cookie) {
  Vue.cookie = true
  Vue.mixin({
    methods: {
      setCookie (cname, cvalue, exminutes) {
        const d = new Date()
        d.setTime(d.getTime() + (exminutes * 60 * 1000))
        let expires = 'expires=' + d.toUTCString()
        document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
      },
      getCookie (cname) {
        let name = cname + '='
        let ca = document.cookie.split(';')
        for (let i = 0; i < ca.length; i++) {
          let c = ca[i]
          while (c.charAt(0) == ' ') {
            c = c.substring(1)
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length)
          }
        }
        return ''
      }
    }
  })
}


