import moment from 'moment'
// import { pl } from 'moment/locale/pl'
// import { en } from 'moment/locale/en-gb'
// import { ru } from 'moment/locale/ru'

export default function ({ app }) {
  // onLanguageSwitched called right after a new locale has been set
  const locale = app.i18n.locale

  if (locale === 'pl') {
    const { pl } = require('moment/locale/pl')
    moment.locale(pl)
  } else if (locale === 'en') {
    const { en } = require('moment/locale/en-gb')
    moment.locale(en)
  } else if (locale === 'uk') {
    const { ru } = require('moment/locale/uk')
    moment.locale(ru)
  }
}
